let appInsights = null;
let logConsole = false;

function setAppInsights(ai) {
  appInsights = ai;
}

function logTrace(message, objectName) {
  generalLog("Trace", message, objectName, "color: #888888");
}

function logInformation(message, objectName) {
  generalLog("Information", message, objectName);
}

function logWarning(message, objectName) {
  generalLog("Warning", message, objectName, "color: #FF8800");
}

function logError(message, objectName) {
  generalLog("Error", message, objectName, "color: #FF0000");
}

function logCritical(message, objectName) {
  generalLog("Critical", message, objectName, "color: #FF00FF");
}

function mapToAILevel(level) {
  switch (level) {
    case "Trace":
      return 0;
    case "Information":
      return 1;
    case "Warning":
      return 2;
    case "Error":
      return 3;
    case "Critical":
      return 4;
  }
}

function setLogConsole(shouldLogConsole) {
  logConsole = shouldLogConsole;
}

function generalLog(level, message, objectName, css) {
  let shouldLogConsole = logConsole;
  if (!css) {
    css = "color: #000000";
  }

  if (appInsights !== null) {
    appInsights.trackTrace({
      message: `${objectName}: ${message}`,
      severity: mapToAILevel(level),
      properties: {}
    });
  }
  else {
    shouldLogConsole = true;
  }

  if (shouldLogConsole) {  // Console logger is default fallback
    if (objectName) {
      console.log(`%c ${level}\\${objectName}: ${message}`, css);
    } else {
      console.log(`%c ${level}: ${message}`, css);
    }
  }
}

export { setAppInsights, logTrace, logInformation, logWarning, logError, logCritical, setLogConsole };
