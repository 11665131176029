<template>
  <nav-drawer-base
    :drawer.sync="innerDrawer"
    :sectionData="sections"
  ></nav-drawer-base>
</template>

<script>
import NavDrawerBase from "./NavDrawerBase.vue";

// @ is an alias to /src
export default {
  name: "NavDrawerUser",
  components: {
    'nav-drawer-base': NavDrawerBase,
  },
  data: () => ({
    innerDrawer: false,
    sections: [
      {
        title: "Resume",
        links: [
          {
            text: "Overview",
            path: "/Resume",
            subLinkSelected: "",
          },
          {
            text: "General",
            path: "/Information",
            subLinkSelected: "",
            subLinks: [
              {
                text: "General Information",
                path: "/Information",
              },
              {
                text: "Address",
                path: "/Address",
              },
              {
                text: "Adjectives",
                path: "/Adjectives",
              },
              {
                text: "Career",
                path: "/Career",
              },
              {
                text: "Hard Skills",
                path: "/HardSkills",
              },
              {
                text: "Soft Skills",
                path: "/SoftSkills",
              },
              {
                text: "Organizations",
                path: "/Organizations",
              },
              {
                text: "Review",
                path: "/ReviewGeneralInfo",
              },
            ],
          },
          {
            text: "Education",
            path: "/Education",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Education Overview",
                path: "/Education",
              },
              {
                text: "General",
                path: "/EducationEntryGeneral",
                hidden: true,
              },
              {
                text: "Tests",
                path: "/EducationEntryDegree",
                hidden: true,
              },
              {
                text: "Awards",
                path: "/EducationEntryAwards",
                hidden: true,
              },
              {
                text: "Award Focus",
                path: "/EducationEntryAwardFocus",
                hidden: true,
              },
            ],
          },
          {
            text: "Work",
            path: "/Work",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Work Overview",
                path: "/Work",
              },
              {
                text: "Company",
                path: "/WorkCompanyGeneral",
                hidden: true,
              },
              {
                text: "Roles",
                path: "/WorkRoles",
                hidden: true,
              },
              {
                text: "Accomplishmets",
                path: "/WorkAccomplishments",
                hidden: true,
              },
            ],
          },
          {
            text: "Final Information",
            path: "/FinalInfo",
            subLinkSelected: "",
          },
          {
            text: "Resume",
            path: "/Review",
            subLinkSelected: "",
          },
        ],
      },
      // {
      //   title: "Cover Letter",
      //   links: [
      //     {
      //       text: "Introduction",
      //       path: "/",
      //       subLinkSelected: ""
      //     },
      //     {
      //       text: "Professional Development",
      //       path: "/TargetIndustry",
      //       subLinkSelected: "",
      //       subLinks: [
      //         {
      //           text: "Target Industry",
      //           path: "/TargetIndustry",
      //         },
      //         {
      //           text: "Target Company",
      //           path: "/TargetCompany",
      //         },
      //       ],
      //     },
      //     {
      //       text: "Strengths",
      //       path: "/Strengths",
      //       subLinkSelected: "",
      //       subLinks: [
      //         {
      //           text: "Strengths Overview",
      //           path: "/Strengths",
      //         },
      //         {
      //           text: "Strengths Category",
      //           path: "/StrengthsCategory",
      //           hidden: true,
      //         },
      //       ],
      //     },
      //     {
      //       text: "Cover Letter",
      //       path: "/",
      //       subLinkSelected: ""
      //     },
      //   ],
      // },
      {
        title: "Review",
        links: [
          {
            text: "Your Resumes",
            path: "/SubmittedResumes",
            subLinkSelected: "",
          },
          {
            text: "Submit Custom Resume",
            path: "/SubmitNewResume",
            subLinkSelected: "",
          },
        ],
      },
    ],
  }),
  props: {
    drawer: {
      type: Boolean,
      default: () => [],
    },
  },
  mounted() {
    this.innerDrawer = this.drawer;
  },
  methods: {},
  watch: {
    drawer: function () {
      this.innerDrawer = this.drawer;
    },
    innerDrawer: function () {
      this.$emit("update:drawer", this.innerDrawer);
    },
  },
  computed: {},
};
</script>