function SetYearCookie(cookie, value, sameSite) {
    let cookieString = `${cookie}=${value}; path=/; max-age=31449600; Secure; SameSite=${sameSite}`;
    document.cookie = cookieString;
}

function ClearCookie(cookie) {
    let cookieString = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure;`;
    document.cookie = cookieString;
}

function GetCookie(name) {
    let cookie = document.cookie.split('; ').find(r => r.startsWith(name));
    if (cookie === undefined || cookie === null || cookie.length === 0) {
        return "";
    }
    let cookieValue = cookie.split("=")[1];
    return cookieValue;
}

export { SetYearCookie, ClearCookie, GetCookie }