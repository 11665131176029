import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Env } from "../environment/EnvironmentFactory";

Vue.use(Vuetify);

let vuetifyInstance = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#0059A3", // var(--v-primary-base)
        secondary: "#F1B500", // #FFCDD2
        accent: "#F1B500", // #3F51B5
        appBar: "#0059A3",
        navDrawer: "#eeeeee",
        error: "#bf0025",
        barGray: "#bbbbbb",
        footerText: "#535353",
        navSelected: "#c6c6c6",
        resumeBackground: "#c6c6c6",
        resumePlaceholder: "#ffffff",
        resumeBacker: "#c6c6c6",
        resumeErrorBox: "#dddddd",
        resumeErrorText: "#333333",
        updatedText: "#707070",
      }
      // Mines
      // light: {
      //   primary: "#002554", // var(--v-primary-base)
      //   secondary: "#B3A369", // #FFCDD2
      //   accent: "#B3A369", // #3F51B5
      //   appBar: "#002554",
      //   navDrawer: "#eeeeee",
      //   error: "#bf0025",
      //   barGray: "#bbbbbb",
      //   footerText: "#535353",
      //   navSelected: "#c6c6c6",
      //   resumeBackground: "#c6c6c6",
      //   resumePlaceholder: "#ffffff",
      //   resumeBacker: "#c6c6c6",
      //   resumeErrorBox: "#dddddd",
      //   resumeErrorText: "#333333",
      //   updatedText: "#707070",
      // }
    }
  }
});

export default vuetifyInstance;

Env()?.GetColors().then(c => {
  vuetifyInstance.framework.theme.themes.light = c;
});