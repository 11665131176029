import * as careerSettingsApi from "../../api/CareerSettingsApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";


function resetState(state) {
    state.colors = {};
    state.images = {};
    state.validDomains = [];
    state.validProviders = [];
    state.displayName = "";
    state.error = "";
}

const environmentStyleModule = {
    namespaced: true,
    state: {
        colors: {},
        images: {},
        validDomains: [],
        validProviders: [],
        displayName: "",
        error: ""
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        loadStyle(state, payload) {
            log.logTrace("Loading Environment Style Data", "environmentStyle_store.loadStyle");
            state.colors = payload.data.colors;
            state.images = payload.data.images;
            state.validDomains = payload.data.validDomains;
            state.validProviders = payload.data.validProviders;
            state.displayName = payload.data.displayName;
        },
        setError(state, payload) {
            state.error = payload;
        },
        logout(state) {
            resetState(state);
        }
    },
    actions: {
        async loadStyle(context) {
            let result = await careerSettingsApi.GetStyle();
            if (!result.success) {
                log.logError(
                    "Failed to load style",
                    "environmentStyle_store.loadStyle"
                );
                context.commit("setError", "Unable to load style information.");
            } else {
                log.logTrace(`Loaded style data.`, "environmentStyle_store.loadStyle");
                context.commit("loadStyle", result);
                context.commit("setError", "");
            }
        },
    }
};

export { environmentStyleModule };
