import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.licensesCerts = [];
  state.licensesCertsDisplay = "";
}

const licenseCertModule = {
  namespaced: true,
  state: {
    licensesCerts: [],
    licensesCertsDisplay: ""
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace(
        "Loading License and Cert Module",
        "licenseCert_store.loadData"
      );
      state.licensesCerts = payload.LicensesCerts;
      state.licensesCertsDisplay = payload.LicensesCertsDisplay;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveLicenseCert(context) {
      let result = await resApi.PostLicenseCerts({
        resumeId: context.rootState.resumeId,
        licensesCerts: context.state.licensesCerts,
        licensesCertsDisplay: context.state.licensesCertsDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save License and Cert data",
          "licenseCert_store.saveLicenseCert"
        );
      }
    }
  }
};

export { licenseCertModule };
