import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.softSkills = [];
  state.softSkillsDisplay = "";
  if (removeBase) {
    state.baseSoftSkills = [];
  }
}

const softSkillsModule = {
  namespaced: true,
  state: {
    softSkills: [],
    softSkillsDisplay: "",
    baseSoftSkills: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Soft Skills Module", "softSkills_store.loadData");
      state.softSkills = payload.SoftSkills;
      state.softSkillsDisplay = payload.SoftSkillsDisplay;
    },
    loadBaseSoftSkills(state, payload) {
      log.logTrace(
        "Loading Base Soft Skills",
        "softSkills_store.loadBaseSoftSkills"
      );
      state.baseSoftSkills = payload.baseSoftSkills;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveSoftSkills(context) {
      let result = await resApi.PostSoftSkills({
        resumeId: context.rootState.resumeId,
        softSkills: context.state.softSkills,
        softSkillsDisplay: context.state.softSkillsDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Soft Skills data",
          "softSkills_store.saveSoftSkills"
        );
      }
    }
  }
};

export { softSkillsModule };
