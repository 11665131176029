import * as careerApi from "../../api/CareerApiUser";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.resumes = [];
}

const userSubmissionsModule = {
  namespaced: true,
  state: {
    resumes: [],
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadResumeSubmissions(state, payload) {
      log.logTrace("Loading User Submissions Module - Resumes", "userSubmissions_store.loadResumeSubmissions");
      state.resumes = payload.data;
      state.resumes.sort((a, b) => {
        try {
          const dtA = new Date(a.dateUpdated);
          const dtB = new Date(b.dateUpdated);
          return dtB.getTime() - dtA.getTime();
        }
        catch {
          return 0;
        }
      });
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async loadResumeSubmissions(context) {
      let result = await careerApi.GetUserReviews();
      if (!result.success) {
        log.logError(
          "Failed to load user's resume submissions",
          "userSubmissions_store.loadResumeSubmissions"
        );
      } else {
        log.logTrace(`Loaded resume submissions.`, "userSubmissions_store.loadResumeSubmissions");
        context.commit("loadResumeSubmissions", result);
      }
    },
  }
};

export { userSubmissionsModule };
