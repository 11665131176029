/* eslint no-dupe-class-members: 0 */

import { SetDomainCookie } from "../cookie/domainCookie";
import store from "../store/index";

// Note - This class calls the getField from vuex-map-fields directly. It's a bit weird.

const LOGO = "logo";

export default class Environment {
    _colors = {};
    _loading = {};

    constructor() {
        // setup domain cookie
        SetDomainCookie(document.location.host);
        this._loading = store.dispatch("envStyle/loadStyle");
    }

    async #resolveLoad() {
        await this._loading;
    }

    async GetColors() {
        let retVal = store.getters['envStyle/getField'].call(store, "colors");
        if (Object.keys(retVal).length > 0) {
            return retVal;
        }

        await this.#resolveLoad();
        return store.getters['envStyle/getField'].call(store, "colors");
    }

    GetIconUrl() {
        throw new Error("Not implemented");
    }

    async #GetImage(imageName) {
        let images = store.getters['envStyle/getField'].call(store, "images");
        if (Object.keys(images).length > 0) {
            return images[imageName];
        }

        await this.#resolveLoad();
        images = store.getters['envStyle/getField'].call(store, "images");
        return images[imageName];
    }

    async GetLogoFilename() {
        return await this.#GetImage(LOGO);
    }

    GetEnvName() {
        let retVal = store.getters['envStyle/getField'].call(store, "displayName");
        return retVal;
    }

    async GetValidLoginProviders() {
        let retVal = store.getters['envStyle/getField'].call(store, "validProviders");
        return retVal;
        // Note: Not waiting for loading as this doesn't get called on Loading, gets called on Login page
    }

    async GetValidDomains() {
        let retVal = store.getters['envStyle/getField'].call(store, "validDomains");
        return retVal;
        // Note: Not waiting for loading as this doesn't get called on Loading, gets called on Login page
    }
}
