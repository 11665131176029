<template>
  <div>
    <v-combobox
      v-model="selectedField"
      :items="optionsList"
      :filter="customFilter"
      :placeholder="label"
      hide-selected
      hide-no-data
      multiple
      v-on:input="updateSelected"
      :autofocus="autofocus"
      :tabindex="tabindex"
      @input.native="saveNative($event)"
    ></v-combobox>
    <v-row no-gutters>
      <v-col cols="12" md="4" class="px-2">
        <v-card
          tile
          flat
          class="d-flex flex-column justify-space-between height-100"
        >
          <div class="height-100">
            <v-card-title class="pt-2 pb-0">
              <v-text-field v-model="lvl1Title" dense></v-text-field>
            </v-card-title>
            <draggable
              class="list-group"
              :list="lvl1List"
              group="people"
              @change="updateListValues()"
            >
              <div
                class="ma-2 drag-item d-flex justify-space-between align-center"
                v-for="sel in lvl1List"
                :key="sel.id"
              >
                <div>{{ sel.text }}</div>
                <div class="single-arrow-container">
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="arrow-button"
                    @click="moveList(sel, 2)"
                  >
                    <v-icon size="14" class="arrow-icon"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="close-button ml-1"
                    @click="removeSelected(sel)"
                    ><v-icon size="12" class="close-icon"
                      >mdi-close</v-icon
                    ></v-btn
                  >
                </div>
              </div>
            </draggable>
          </div>
          <v-system-bar
            :class="barClass(lvl1List)"
            height="2"
            class="mx-4"
          ></v-system-bar>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="px-2">
        <v-card
          tile
          flat
          class="d-flex flex-column justify-space-between height-100"
        >
          <div class="height-100">
            <v-card-title class="pt-2 pb-0">
              <v-text-field v-model="lvl2Title" dense></v-text-field>
            </v-card-title>
            <draggable
              class="list-group"
              :list="lvl2List"
              group="people"
              @change="updateListValues()"
            >
              <div
                class="ma-2 drag-item d-flex justify-space-between align-center"
                v-for="sel in lvl2List"
                :key="sel.id"
              >
                <div>{{ sel.text }}</div>
                <div class="double-arrow-container">
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="arrow-button"
                    @click="moveList(sel, 1)"
                  >
                    <!-- <v-icon size="14" class="arrow-icon">mdi-arrow-left-thick</v-icon> -->
                    <v-icon size="14" class="arrow-icon"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="arrow-button"
                    @click="moveList(sel, 3)"
                  >
                    <v-icon size="14" class="arrow-icon"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="close-button ml-1"
                    @click="removeSelected(sel)"
                  >
                    <v-icon size="12" class="close-icon">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </draggable>
          </div>
          <v-system-bar
            :class="barClass(lvl2List)"
            height="2"
            class="mx-4"
          ></v-system-bar>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="px-2">
        <v-card
          tile
          flat
          class="d-flex flex-column justify-space-between height-100"
        >
          <div class="height-100">
            <v-card-title class="pt-2 pb-0">
              <v-text-field v-model="lvl3Title" dense></v-text-field>
            </v-card-title>
            <draggable
              class="list-group"
              :list="lvl3List"
              group="people"
              @change="updateListValues()"
            >
              <div
                class="ma-2 drag-item d-flex justify-space-between align-center"
                v-for="sel in lvl3List"
                :key="sel.id"
              >
                <div>{{ sel.text }}</div>
                <div class="single-arrow-container">
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="arrow-button"
                    @click="moveList(sel, 2)"
                  >
                    <!-- <v-icon size="14" class="arrow-icon">mdi-arrow-left-thick</v-icon> -->
                    <v-icon size="14" class="arrow-icon"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    max-width="15"
                    max-height="15"
                    class="close-button ml-2 mr-1"
                    @click="removeSelected(sel)"
                  >
                    <v-icon size="12" class="close-icon">mdi-close </v-icon>
                  </v-btn>
                </div>
              </div>
            </draggable>
          </div>
          <v-system-bar
            :class="barClass(lvl3List)"
            height="2"
            class="mx-4"
          ></v-system-bar>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as UUID from "../utility/guid";
import draggable from "vuedraggable";

//https://sortablejs.github.io/Vue.Draggable/#/two-lists

// @ is an alias to /src
export default {
  name: "MultiSelectLevels",
  data: () => ({
    internalSelected: [],
    selectedField: [],
    menuprops: { openOnClick: false },
    nativeInput: "",
    internalCategoryLabels: [],
    lvl1Title: "",
    lvl2Title: "",
    lvl3Title: "",
    lvl1List: [],
    lvl2List: [],
    lvl3List: [],
  }),
  components: {
    draggable: draggable,
  },
  props: {
    optionsList: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    selected: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "",
    },
    categoryLabels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  mounted() {
    if (this.internalSelected.length == 0 && this.selected.length != 0) {
      this.internalSelected = this.selected;
    }

    if (this.categoryLabels === null || this.categoryLabels.length != 3) {
      this.internalCategoryLabels = [];
      this.internalCategoryLabels.push({ text: "Advanced", level: "1" });
      this.internalCategoryLabels.push({ text: "Familiar", level: "2" });
      this.internalCategoryLabels.push({ text: "Basic", level: "3" });
      // emit update
    } else {
      this.internalCategoryLabels = this.categoryLabels;
    }

    this.lvl1Title = this.internalCategoryLabels[0].text;
    this.lvl2Title = this.internalCategoryLabels[1].text;
    this.lvl3Title = this.internalCategoryLabels[2].text;

    // build lists
    this.buildLists();
  },
  watch: {
    selected: function (newSelected) {
      if (this.internalSelected.length == 0 && newSelected.length != 0) {
        this.internalSelected = newSelected;
      }
    },
    lvl1Title: function () {
      this.emitLabelUpdate();
    },
    lvl2Title: function () {
      this.emitLabelUpdate();
    },
    lvl3Title: function () {
      this.emitLabelUpdate();
    },
  },
  beforeDestroy() {
    // Vue 3 updates to beforeUnmount()
    // save the native value if the user didn't trigger the input event on the combobox
    if (this.nativeInput.length > 0) {
      this.selectedField = [this.nativeInput];
      this.updateSelected();
    }
  },
  methods: {
    moveList(item, level) {
      item.level = level;

      // kinda janky - this emits the update, which propogates up to the parent (and vuex)
      // then down into selected, which populates internalSelected,
      // then the next method rebuilds the lists off of that...
      this.emitUpdate();

      this.buildLists();
    },
    barClass(list) {
      if (list === undefined || list === null) {
        return "empty";
      }

      if (list.length > 0) {
        return "full";
      }

      return "empty";
    },
    saveNative(evt) {
      this.nativeInput = evt.srcElement.value;
    },
    updateListValues() {
      // update the values in the lists to have the right level value.
      this.lvl1List = this.lvl1List.map((i) => {
        i.level = 1;
        return i;
      });

      this.lvl2List = this.lvl2List.map((i) => {
        i.level = 2;
        return i;
      });

      this.lvl3List = this.lvl3List.map((i) => {
        i.level = 3;
        return i;
      });

      this.emitUpdate();
    },
    buildLists() {
      // build list 1, 2, 3 from internalSelected.
      this.lvl1List = this.internalSelected.filter((i) => i.level === 1);
      let tempLvl2 = this.internalSelected.filter((i) => i.level === 2);
      this.lvl3List = this.internalSelected.filter((i) => i.level === 3);

      // handle items that are unset.
      this.lvl2List = tempLvl2.concat(
        this.internalSelected
          .filter((i) => i.level === undefined || i.level === null)
          .map((i) => {
            i.level = 2;
            return i;
          })
      );
    },
    emitLabelUpdate() {
      this.internalCategoryLabels[0].text = this.lvl1Title;
      this.internalCategoryLabels[1].text = this.lvl2Title;
      this.internalCategoryLabels[2].text = this.lvl3Title;

      this.$emit("update:categoryLabels", this.internalCategoryLabels);
    },
    emitUpdate() {
      // return combined lists with values instead of the internal selected list.
      let combinedList = [...this.lvl1List, ...this.lvl2List, ...this.lvl3List];

      // emit update
      this.$emit("update:selected", combinedList);
      this.$emit("input", combinedList);
    },
    updateSelected() {
      // Create a new item from the autocomplete
      if (
        this.selectedField.length > 0 &&
        this.selectedField[0].length > 0 &&
        !this.internalSelected.some(
          (item) => item.text == this.selectedField[0]
        )
      ) {
        this.internalSelected.push({
          text: this.selectedField[0],
          id: UUID.gen(),
          level: 2,
        });

        this.buildLists();

        this.emitUpdate();

        // clean native input tracker
        this.nativeInput = "";
      }
      this.selectedField = [];
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.toLowerCase();
      const textTwo = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();

      if (queryText.length > 2) {
        return (
          textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        );
      }

      return false;
    },
    removeSelected: function (selectedValue) {
      if (
        selectedValue.text.length > 0 &&
        this.internalSelected.some((item) => item.id == selectedValue.id)
      ) {
        this.internalSelected = this.internalSelected.filter(
          (item) => item.id != selectedValue.id
        );

        this.buildLists();

        this.emitUpdate();
      }
    },
  },
};
</script>

<style scoped>
.list-group {
  min-height: 50px;
  height: 80%; /* Imprecise, but works well enough. */
}

.drag-item {
  font-size: 14px;
  border-style: solid;
  border-color: #e0e0e0;
  border-width: 1px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 4px;
}

.drag-item:hover {
  background-color: #f6f6f6;
}

.drag-item:focus {
  background-color: #e5e5e5;
}

.arrow-button {
  background-color: transparent;
}

.arrow-icon {
  color: var(--v-barGray-base);
}

.arrow-icon::before {
  font-weight: 600;
}

.close-button {
  background-color: #202020;
}

.close-icon {
  color: #ffffff !important;
}

.close-icon::before {
  font-weight: 600;
}

.full {
  background-color: var(--v-primary-base);
}

.empty {
  background-color: var(--v-barGray-base);
}

.single-arrow-container {
  min-width: 35px;
}

.double-arrow-container {
  min-width: 50px;
}
</style>