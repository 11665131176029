import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.industries = [];
  state.targetIndustry = "";
  state.industrySkills = [];
  state.otherSkills = [];
  state.industryReasons = "";
  if (removeBase) {
    state.baseTargetIndustries = [];
  }
}

const targetIndustryModule = {
  namespaced: true,
  state: {
    industries: [],
    targetIndustry: "",
    industrySkills: [],
    otherSkills: [],
    industryReasons: "",
    baseTargetIndustries: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace(
        "Loading Target Industries Module",
        "targetIndustry_store.loadData"
      );
      state.industries = payload.industries;
      state.targetIndustry = payload.targetIndustry;
      state.industrySkills = payload.industrySkills;
      state.otherSkills = payload.otherSkills;
      state.industryReasons = payload.industryReasons;
    },
    loadBaseIndustries(state, payload) {
      log.logTrace(
        "Loading Base Industries",
        "targetIndustry_store.loadBaseIndustries"
      );
      state.baseTargetIndustries = payload.baseIndustries;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveTargetIndustries(context) {
      let result = await resApi.PostTargetIndustries({
        resumeId: context.rootState.resumeId,
        industries: context.state.industries,
        targetIndustry: context.state.targetIndustry,
        industrySkills: context.state.industrySkills,
        otherSkills: context.state.otherSkills,
        industryReasons: context.state.industryReasons
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Target Industry data",
          "targetIndustry_store.saveTargetIndustries"
        );
      }
    }
  }
};

export { targetIndustryModule };
