import * as dutil from "@/utility/dateutil.js";
import * as disp from "@/utility/display.js";

function buildGeneralDisplay(educationGeneral) {
  let tStr = "";
  if (educationGeneral.schoolName?.length > 0) {
    tStr += educationGeneral.schoolName;
    if (educationGeneral.universityName?.length > 0) {
      tStr += ", "
    }
  }

  if (educationGeneral.universityName?.length > 0) {
    tStr += educationGeneral.universityName;
  }

  return tStr.trim();
}

function buildLocationDisplay(educationGeneral) {
  return disp.CityStateCountryString(educationGeneral.universityCity,
    educationGeneral.universityState,
    educationGeneral.country);
}

function buildDateDisplay(educationGeneral) {
  var startDate = (Object.prototype.toString.call(educationGeneral.dateStart) ===
    "[object Date]"
    ? dutil.dateToMonthYear(educationGeneral.dateStart)
    : "");
  var endDate = (Object.prototype.toString.call(educationGeneral.dateEnd) ===
    "[object Date]"
    ? dutil.dateToMonthYear(educationGeneral.dateEnd)
    : "");

  let sb = "";
  if (startDate.length > 0) {
    sb += startDate.trim();
  }
  if (!educationGeneral.graduated || endDate.length > 0) {
    if (sb.length > 0) {
      sb += " - ";
    }
    if (educationGeneral.graduated) {
      sb += `${endDate.trim()}`;
    } else {
      sb += 'Present';
    }
  }

  return sb.trim();
}

function buildExpectedGrad(educationGeneral) {
  let dateStr = (Object.prototype.toString.call(educationGeneral.dateExpected) ===
    "[object Date]"
    ? dutil.dateToMonthYear(educationGeneral.dateExpected)
    : "").trim();

  if (!educationGeneral.hasExpectedGrad || dateStr.length == 0 || educationGeneral.graduated) {
    return "";
  }

  return `Expected Graduation: ${dateStr}`;
}

function buildGPADisplay(educationGPA) {
  let rSTR = "";
  if (educationGPA.gpa?.length > 0) {
    rSTR += `GPA: ${educationGPA.gpa}`;
    if (educationGPA.gpaScale?.length > 0) {
      rSTR += ` / ${educationGPA.gpaScale}`;
    }
  }

  return rSTR.trim();
}

function buildDegree(educationDegree) {
  let degreeDisp =
    (educationDegree.degreeName.length > 0 ? educationDegree.degreeName : "") +
    (educationDegree.degreeArea.length > 0
      ? ", " + educationDegree.degreeArea
      : "");

  return degreeDisp.trim();
}

function buildMinorDegree(educationDegreeMinor) {
  let minorDisp =
    educationDegreeMinor?.minors?.length > 0
      ? "Minor: " + educationDegreeMinor?.minors
      : "";
  return minorDisp.trim();
}

function buildAccolades(educationAccolades) {
  let display = educationAccolades
    .filter(i => i.selected === true)
    .map(i => (i.useCustom ? i.displayCustom.trim() : i.displayAuto.trim()));
  return display;
}

export {
  buildGeneralDisplay,
  buildLocationDisplay,
  buildDateDisplay,
  buildExpectedGrad,
  buildGPADisplay,
  buildDegree,
  buildMinorDegree,
  buildAccolades
};
