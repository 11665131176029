import * as envAdminApi from "../../api/CareerApiAdmin";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.activeReviews = [];
  state.users = [];
  state.adminUsers = [];
  state.name = "";
  state.envId = "";
}

const environmentModule = {
  namespaced: true,
  state: {
    activeReviews: [],
    users: [],
    adminUsers: [],
    name: "",
    envId: "",
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadActiveReviews(state, payload) {
      log.logTrace("Loading Environment Module Active Reviews", "environment_store.loadActiveReviews");
      state.activeReviews = payload.data;
    },
    loadUsers(state, payload) {
      log.logTrace("Loading Environment Module Users", "environment_store.loadUsers");
      state.users = payload.data;
    },
    loadEnvironment(state, payload) {
      log.logTrace("Loading Environment Module Environment Data", "environment_store.loadEnvironment");
      state.adminUsers = payload.data.permissions;
      state.name = payload.data.name;
      state.envId = payload.data.envId;
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async addUser(context, payload) // eslint-disable-line
    {
      let result = await envAdminApi.AddAdminUser(payload.userName, payload.permission);
      if (!result.success) {
        log.logError(
          "Failed to add user",
          "environment_store.addUser"
        );
      }
      else {
        log.logTrace(`Added user.`, "environment_store.addUser");
      }
    },
    async removeUser(context, payload) // eslint-disable-line
    {
      let result = await envAdminApi.RemoveAdminUser(payload.userName)
      if (!result.success) {
        log.logError(
          "Failed to remove user",
          "environment_store.removeUser"
        );
      }
      else {
        log.logTrace(`Removed user.`, "environment_store.removeUser");
      }
    },
    async loadActiveReviews(context) {
      let result = await envAdminApi.GetActiveReviews();
      if (!result.success) {
        log.logError(
          "Failed to load active reviews",
          "environment_store.loadActiveReviews"
        );
      } else {
        log.logTrace(`Loaded active reviews.`, "environment_store.loadActiveReviews");
        context.commit("loadActiveReviews", result);
      }
    },
    async loadUsers(context) {
      let result = await envAdminApi.GetEnvironmentUsers();
      if (!result.success) {
        log.logError(
          "Failed to load environment users",
          "environment_store.loadUsers"
        );
      } else {
        log.logTrace(`Loaded environment users.`, "environment_store.loadUsers");
        context.commit("loadUsers", result);
      }
    },
    async loadEnvironment(context) { // eslint-disable-line
      let result = await envAdminApi.GetEnvironment();
      if (!result.success) {
        log.logError("Failed to load general environment information", "environment_store.loadEnvironment");
      }
      else {
        log.logTrace(`Loaded environment data.`, "environment_store.loadEnvironment");
        context.commit("loadEnvironment", result);
      }
    },
  }
};

export { environmentModule };
