<template>
  <status-bar v-model="status">
    <v-switch
      :input-value="value"
      :label="label"
      :tabindex="tabindex"
      :autofocus="autofocus"
      v-on:change="updateValue($event)"
    ></v-switch>
  </status-bar>
</template>

<script>
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationSwitch",
  components: {
    "status-bar": StatusBar
  },
  data: () => ({}),
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    tabindex: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    }
  },
  mounted() {},
  computed: {
    status() {
      if (this.value) {
        return "Y";
      }
      return "";
    }
  }
};
</script>
