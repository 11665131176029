<template>
  <div class="bar-holder">
    <div class="bar-background"></div>
    <div :class="barClass(value)"></div>
    <slot></slot>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "StatusBar",
  data: () => ({}),
  props: {
    value: {
      type: [String, Number, Boolean]
    },
    inError: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  methods: {
    barClass(item) {
      if (item === undefined || item === null) {
        return "bar";
      }

      if (this.inError) {
        return "bar bar-error bar-filled";
      }

      if (typeof item === "boolean" && item == true) {
        return "bar bar-filled";
      }

      if (typeof item === "number" && item > 0) {
        return "bar bar-filled";
      }

      if (typeof item === "string" && item?.length > 0) {
        return "bar bar-filled";
      }

      return "bar";
    }
  },
  mounted() {},
  computed: {}
};
</script>

<style>
.bar-background {
  background-color: var(--v-barGray-base);
  height: 100%;
  width: 5px;
  left: 0px;
  top: 0px;
  position: absolute;
}

.bar {
  background-color: var(--v-primary-base);
  height: 100%;
  transform: translateY(-100%);
  width: 5px;
  left: 0px;
  top: 0px;
  position: absolute;
  transition: transform 1s;
}

.bar-error {
  background-color: var(--v-error-base);
}

.bar-filled {
  transform: translateY(0%);
  transition: transform 1s;
}

.bar-holder {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 12px;
  height: 100%;
  overflow: hidden;
}
</style>
