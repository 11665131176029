import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.personalInterests = [];
  state.personalInterestsDisplay = "";
}

const personalInterestModule = {
  namespaced: true,
  state: {
    personalInterests: [],
    personalInterestsDisplay: ""
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace(
        "Loading Personal Interests Module",
        "personalInterest_store.loadData"
      );
      state.personalInterests = payload.PersonalInterests;
      state.personalInterestsDisplay = payload.PersonalInterestsDisplay;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async savePersonalInterests(context) {
      let result = await resApi.PostPersonalInterest({
        resumeId: context.rootState.resumeId,
        personalInterests: context.state.personalInterests,
        personalInterestsDisplay: context.state.personalInterestsDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Personal Interest data",
          "personalInterest_store.savePersonalInterests"
        );
      }
    }
  }
};

export { personalInterestModule };
