import Environment from "./Environment";

var _env = null;

function Env() {
    if (_env !== null) {
        return _env;
    }

    _env = new Environment();

    return _env;
}

export { Env }