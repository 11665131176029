import * as log from "./logging/logger.js";

/* Code used by education, work, and strength stores for sorting their data */

// Assumes that array is a list of objects that may or may not have a property called 'order'
function MaxOrder(dict) {
  let array = Object.values(dict).filter(v => v !== null);
  let maxLen = array.length;
  let maxOrder = array
    .map(i => {
      if (i.order === undefined || i.order == null) {
        return 0;
      }
      return i.order;
    })
    .reduce((m, i) => (i > m ? i : m), -1000);

  if (maxLen > maxOrder) {
    maxOrder = maxLen;
  }
  return maxOrder;
}

function CompareOrder(elem1, elem2) {
  if (elem1.order < elem2.order) {
    return -1;
  }
  if (elem1.order > elem2.order) {
    return 1;
  }
  return 0;
}

// Compare the order of the first element of each element (assumes they are arrays)
function CompareSubOneOrder(elem1, elem2) {
  if (elem1[1].order < elem2[1].order) {
    return -1;
  }
  if (elem1[1].order > elem2[1].order) {
    return 1;
  }
  return 0;
}

// orderType must be "up" or "down"
function OrderChange(dict, key, orderType) {
  let offsetValue = 0;
  if (orderType === "up") {
    offsetValue = -1;
  } else if (orderType === "down") {
    offsetValue = 1;
  } else {
    log.logWarning(
      `orderType of ${orderType} is not up or down.`,
      "sorting.orderChange"
    );
    return;
  }

  // key must be a key in the dict.
  if (!Object.keys(dict).includes(key)) {
    log.logWarning(
      `order key ${key} was not found in dictionary`,
      "sorting.orderChange"
    );
    return;
  }

  let x = Object.entries(dict).filter(e => e[1] !== null);
  x.sort(CompareSubOneOrder);
  let i = 0;
  for (i = 0; i < x.length; i++) {
    if (x[i][0] === key) {
      // if we're at the key
      if (orderType === "up") {
        if (i === 0) {
          return;
        }
      } else {
        // order type is down
        if (i === x.length - 1) {
          return;
        }
      }

      let iOrder = x[i][1].order;
      let swapOrder = x[i + offsetValue][1].order;
      dict[x[i][0]].order = swapOrder;
      dict[x[i + offsetValue][0]].order = iOrder;
      break;
    }
  }
}

export { MaxOrder, CompareOrder, CompareSubOneOrder, OrderChange };
