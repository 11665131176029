export function GetOrigin() {
  let url = process.env.VUE_APP_SERVER_BASE_URL;
  if (url != null) {
    return url;
  } else {
    return window.location.origin;
  }
}

export function IsDevelopmentEnv() {
  let envString = process.env.VUE_APP_ENVIRONMENT;
  if (envString != null && envString === "local") {
    return true;
  }
  return false;
}