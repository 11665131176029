import * as base from "./cookieBase"

// Code to set domain cookie
const RSDOMAINCOOKIE = 'rs_dm';

/* -- Set Domain Cookie for notifying server of current domain -- */
function SetDomainCookie(domain) {
    base.SetYearCookie(RSDOMAINCOOKIE, domain, "None");
}

export { SetDomainCookie };