<template>
  <status-bar v-model="anyValues" class="pb-4">
    <h3 v-if="titleText.length > 0" class="pb-3 pt-2">{{ titleText }}</h3>
    <h3
      v-if="subText.length > 0"
      class="text-body-1 font-italic text--secondary"
    >
      {{ subText }}
    </h3>
    <multi-select-levels
      :optionsList="optionsList"
      :label="label"
      :selected="selected"
      :autofocus="autofocus"
      :tabindex="tabindex"
      v-on:input="test($event)"
      :categoryLabels="categoryLabels"
      @update:categoryLabels="updateCatLabels($event)"
    >
    </multi-select-levels>
  </status-bar>
</template>

<script>
import MultiSelectLevels from "./MultiSelectLevels.vue";
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationMultiSelect",
  components: {
    "multi-select-levels": MultiSelectLevels,
    "status-bar": StatusBar
  },
  data: () => ({}),
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    titleText: {
      type: String,
      default: ""
    },
    subText: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ""
    },
    categoryLabels: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    anyValues() {
      if (this.selected.length > 0) {
        return "V";
      }
      return "";
    }
  },
  mounted() {},
  watch: {},
  methods: {
    test(data) {
      this.$emit("update:selected", data);
    },
    updateCatLabels(data) {
      this.$emit("update:categoryLabels", data);
    }
  }
};
</script>
