import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.selectedAdjectives = [];
  state.adjectiveDisplay = "";
  if (removeBase) {
    state.baseAdjectives = [];
  }
}

const adjectiveModule = {
  namespaced: true,
  state: {
    selectedAdjectives: [],
    adjectiveDisplay: "",
    baseAdjectives: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Adjective Module", "adjective_store.loadData");
      state.selectedAdjectives = payload.Adjectives;
      state.adjectiveDisplay = payload.AdjectiveDisplay;
    },
    loadBaseAdjectives(state, payload) {
      log.logTrace(
        "Loading Base Adjectives",
        "adjective_store.loadBaseAdjectives"
      );
      state.baseAdjectives = payload.baseAdjectives;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveAdjective(context) {
      let result = await resApi.PostAdjective({
        resumeId: context.rootState.resumeId,
        adjectives: context.state.selectedAdjectives,
        adjectiveDisplay: context.state.adjectiveDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save adjective data",
          "adjective_store.saveAdjective"
        );
      }
    }
  }
};

export { adjectiveModule };
