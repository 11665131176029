<template>
  <v-expansion-panels
    class="rounded-0"
    flat
    accordion
    :value="value"
    v-on:change="updateValue($event)"
    :multiple="multiple"
  >
    <slot></slot>
  </v-expansion-panels>
</template>

<script>
import StatusBar from "../StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationExpansionPanels",
  data: () => ({}),
  props: {
    value: {
      type: [Number, Array]
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "status-bar": StatusBar,  //eslint-disable-line
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  },
  mounted() {},
  computed: {}
};
</script>
