<template>
  <status-bar v-model="valueAsString">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" sm="6" class="r-col pr-sm-3">
          <v-autocomplete
            :label="label"
            auto-select-first
            :items="validMonths"
            v-model="monthValue"
            @change="triggerUpdate()"
            :tabindex="tabindex"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="r-col pl-sm-3">
          <v-autocomplete
            :items="validYears"
            v-model="yearValue"
            @change="triggerUpdate()"
            :tabindex="tiPlusOne"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </status-bar>
</template>

<script>
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "MonthDateSelect",
  components: {
    "status-bar": StatusBar,
  },
  data: () => ({
    internalDate: new Date(),
    monthValue: 0,
    yearValue: 0,
    validMonths: [],
    validYears: [],
  }),
  props: {
    value: {
      type: Date,
      default: () => new Date(1990, 1, 1),
    },
    label: {
      type: String,
      default: "",
    },
    tabindex: {
      type: String,
      default: "",
    },
    extraYears: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.validMonths = [...Array(12).keys()].map((x) => {
      return { text: monthNames[x], value: x };
    });

    let curYearOffset = new Date().getFullYear() - 99 + this.extraYears; // deal with 0 array index start

    this.validYears = [...Array(100).keys()]
      .map((x) => {
        return { text: x + curYearOffset, value: x + curYearOffset };
      })
      .reverse();

    this.buildFromDate(this.value);
  },
  watch: {
    value: function () {
      this.buildFromDate(this.value);
    },
  },
  methods: {
    buildFromDate(dateValue) {
      if (Object.prototype.toString.call(dateValue) === "[object Date]") {
        this.monthValue = dateValue.getMonth();
        this.yearValue = dateValue.getFullYear();
      }
    },
    triggerUpdate() {
      this.internalDate = new Date(this.yearValue, this.monthValue, 1);
      this.$emit("update:value", this.internalDate);
    },
  },
  computed: {
    valueAsString() {
      return this.value?.toString();
    },
    tiPlusOne() {
      let tabNum = parseInt(this.tabindex);
      return (tabNum + 1).toString();
    },
  },
};
</script>
