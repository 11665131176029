<template>
  <r-dialog :value="value" @input="updateValue($event)" title="Cookie Manager">
    <v-card-text class="text--primary text-body-1 pt-4 pb-3">
      <p class="text-body-2">
        When you visit our website, we store information on your browser -
        typically in the form of cookies. You can choose to not allow some types
        of cookies. Use the options below to allow or block the different
        categories of cookies that we collect.
      </p>
      <p class="text-body-2">
        For more information, you can review our
        <a href="/CookieNotice" target="_blank">Cookie Notice</a>.
      </p>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <div>
          <span class="text-body-1 text--primary">Essential Cookies</span>
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pb-1 pl-1" small v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              These cookies are strictly necessary to provide you with services
              available through our Websites. You cannot disable these cookies.
            </span>
          </v-tooltip>
        </div>
        <div>
          <v-switch
            disabled
            color="primary"
            v-model="essentialCookies"
          ></v-switch>
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div>
          <span class="text-body-1 text--primary">
            Performance and Functionality Cookies
          </span>
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pb-1 pl-1" small v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              These cookies are used to enhance the performance and
              functionality of our Websites but are non-essential to their use.
            </span>
          </v-tooltip>
        </div>
        <div>
          <v-switch color="primary" v-model="perfCookies"></v-switch>
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div>
          <span class="text-body-1 text--primary">
            Analytics and Customization Cookies
          </span>
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pb-1 pl-1" small v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              These cookies collect information that can be used to do things
              like help us understand how our Websites are being used, or
              troubleshoot issues.
            </span>
          </v-tooltip>
        </div>
        <div>
          <v-switch color="primary" v-model="analyticsCookies"></v-switch>
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div>
          <span class="text-body-1 text--primary">Advertising Cookies</span>
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pb-1 pl-1" small v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              These cookies are used to make advertising messages more relevant
              to you.
            </span>
          </v-tooltip>
        </div>
        <div>
          <v-switch color="primary" v-model="advertCookies"></v-switch>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row class="ma-0">
        <v-col cols="12" sm="4">
          <v-btn color="primary" @click="saveSettings()"> Save Choices </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn color="primary" outlined @click="acceptAll()">
            Accept All
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn color="gray" text @click="updateValue(false)"> Cancel </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-card-actions>
  </r-dialog>
</template>

<script>
import * as cookie from "../cookie/cookie.js";

// @ is an alias to /src
export default {
  name: "CookieManager",
  data: () => ({
    essentialCookies: true,
    perfCookies: false,
    analyticsCookies: false,
    advertCookies: false,
  }),
  props: {
    value: {
      type: Boolean,
    },
  },
  watch: {
    value: {
      handler() {
        this.updateCookieState();
      },
    },
  },
  components: {},
  methods: {
    saveSettings() {
      cookie.SetCookiePrompt("1");
      cookie.SetPerfCookie(this.getValue(this.perfCookies));
      cookie.SetAnalyticsCookie(this.getValue(this.analyticsCookies));
      cookie.SetAdvertisingCookie(this.getValue(this.advertCookies));

      if (!this.analyticsCookies) {
        cookie.ClearAnalyticsCookies();
      } else {
        cookie.InitGoogleTagManager();
      }

      this.promptUpdated();
      this.updateValue(false);
    },
    acceptAll() {
      this.perfCookies = true;
      this.analyticsCookies = true;
      this.advertCookies = true;
      this.saveSettings();
    },
    promptUpdated() {
      this.$emit("prompt");
    },
    updateValue: function (value) {
      this.$emit("input", value);
    },
    getValue(val) {
      if (val) {
        return "1";
      } else {
        return "0";
      }
    },
    updateCookieState() {
      this.perfCookies = cookie.UsePerf();
      this.analyticsCookies = cookie.UseAnalytics();
      this.advertCookies = cookie.UseAdvertising();
    },
  },
  mounted() {
    this.updateCookieState();
  },
  computed: {},
};
</script>
