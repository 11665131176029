import * as base from "./cookieBase"

// Code to set redirect cookie
const RSREDIRECTCOOKIE = 'rs_rd';

function GetRedirectCookie() {
    return base.GetCookie(RSREDIRECTCOOKIE);
}

/* -- Set Redirect Cookie for handling redirect after login -- */
function SetRedirectCookie(domain) {
    base.SetYearCookie(RSREDIRECTCOOKIE, domain, "None");
}

function ClearRedirectCookie() {
    base.ClearCookie(RSREDIRECTCOOKIE);
}

export { SetRedirectCookie, ClearRedirectCookie, GetRedirectCookie };