import Vue from "vue";
import VueRouter from "vue-router";
import ResumeLanding from "../views/ResumeLanding.vue";
import Login from "../views/login/Login.vue";
import Home from "../views/Home.vue";
import store from "../store/index";
import { Env } from "../environment/EnvironmentFactory";


Vue.use(VueRouter);

function BuildLoginPath(base, to) {
  if (to === undefined || to === null || to.path === undefined || to.path === null) {
    return base;
  }
  return `${base}?t=${to.path.replace('/', '')}`;
}

const LOGIN = "Login";
const DEVLOGIN = "Developer Login";
const LOGOUT = "Log Out";
const TERMS = "Terms Of Use";
const PRIVACY = "Privacy Notice";
const COOKIE = "Cookie Notice";
const HOME = "Home";
const FORGOTPWD = "Forgot Password";
const FORGOTPWDACTION = "Forgot Password Action";
const CREATEACCOUNT = "Create Account";
const CONFIRMEMAILACTION = "Confirm Email Action";

// Career mocks
const CAREERERROR = "Resumation Career Center Error";

const TITLEBASE = "Resumation";

const REVIEWLIST = "Active Resume List";
const REVIEWWRITER = "Resume Review Writer";
const TESTER = "Tester"
const DASHBOARD = "Career Dashboard";
const ALLUSERS = "All Users";
const USERDETAILS = "User Details";
const ADMINUSERS = "Admin Users";
const LOADING = "Loading";
const CONFIRMEMAILREQUIRED = "Email Confirmation Required";

// Common pages
const OPTIONS = "Options";

const ADMINPAGES = [REVIEWLIST, REVIEWWRITER, TESTER, DASHBOARD, ALLUSERS, USERDETAILS, ADMINUSERS, CONFIRMEMAILREQUIRED];
const COMMONPAGES = [OPTIONS];

const routes = [
  {
    path: "/",
    name: HOME,
    component: Home,
    meta: { hideNav: true, hideBar: true }
  },
  {
    path: "/Resume",
    name: "ResumeLanding",
    component: ResumeLanding,
    meta: { hideNav: true, hideBar: false }
  },
  {
    path: "/login",
    name: LOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: Login
  },
  {
    path: "/dev/Login",
    name: DEVLOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: () => import("../views/login/DevLogin.vue")
  },
  {
    path: "/logout",
    name: LOGOUT,
    meta: { hideNav: true, hideBar: true },
    component: () => import("../views/login/Logout.vue")
  },
  {
    path: "/information",
    name: "Information",
    meta: { title: "General Information" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Information.vue")
  },
  {
    path: "/address",
    name: "Address",
    meta: { title: "Address" },
    component: () => import("../views/Address.vue")
  },
  {
    path: "/education",
    name: "Education",
    meta: { title: "Education Overview" },
    component: () => import("../views/Education.vue")
  },
  {
    path: "/adjectives",
    name: "Adjectives and Roles",
    meta: { title: "Adjectives and Roles" },
    component: () => import("../views/Adjectives.vue")
  },
  {
    path: "/career",
    name: "Career and Personal Interests",
    meta: { title: "Career and Personal Interests" },
    component: () => import("../views/CareerInterest.vue")
  },
  {
    path: "/hardskills",
    name: "Technical and Hard Skills",
    meta: { title: "Technical and Hard Skills" },
    component: () => import("../views/HardSkills.vue")
  },
  {
    path: "/softskills",
    name: "Languages and Soft Skills",
    meta: { title: "Languages and Soft Skills" },
    component: () => import("../views/SoftSkills.vue")
  },
  {
    path: "/organizations",
    name: "Organizations and Volunteering",
    meta: { title: "Organizations and Volunteering" },
    component: () => import("../views/Organizations.vue")
  },
  {
    path: "/educationEntryGeneral/:key",
    name: "Education Entry",
    meta: { title: "Education - General Information" },
    component: () => import("../views/EducationEntryGeneral.vue")
  },
  {
    path: "/educationEntryDegree/:key",
    name: "Education Entry - Degree",
    meta: { title: "Education - Degree" },
    component: () => import("../views/EducationEntryDegree.vue")
  },
  {
    path: "/educationEntryAwards/:key",
    name: "Education Entry - Awards",
    meta: { title: "Education - Awards" },
    component: () => import("../views/EducationEntryAwards.vue")
  },
  {
    path: "/educationEntryAwardFocus/:key",
    name: "Education Entry - Award Focus",
    meta: { title: "Education - Award Focus" },
    component: () => import("../views/EducationEntryAwardFocus.vue")
  },
  {
    path: "/WorkCompanyGeneral/:key",
    name: "General Company Information",
    meta: { title: "Work - General Information" },
    component: () => import("../views/WorkCompanyGeneral.vue")
  },
  {
    path: "/WorkRoles/:key",
    name: "Work Roles",
    meta: { title: "Work - Roles" },
    component: () => import("../views/WorkRoles.vue")
  },
  {
    path: "/WorkAccomplishments/:key",
    name: "Work Accomplishments",
    meta: { title: "Work - Accomplishments" },
    component: () => import("../views/WorkAccomplishments.vue")
  },
  {
    path: "/Work",
    name: "Work",
    meta: { title: "Work Overview" },
    component: () => import("../views/Work.vue")
  },
  {
    path: "/TargetIndustry",
    name: "Target Industry",
    meta: { title: "Target Industry" },
    component: () => import("../views/TargetIndustry.vue")
  },
  {
    path: "/TargetCompany",
    name: "Target Company",
    meta: { title: "Target Company" },
    component: () => import("../views/TargetCompany.vue")
  },
  {
    path: "/Strengths",
    name: "Strengths",
    meta: { title: "Strengths Overview" },
    component: () => import("../views/Strengths.vue")
  },
  {
    path: "/StrengthsCategory/:key",
    name: "Strengths Category",
    meta: { title: "Strengths - Category" },
    component: () => import("../views/StrengthsCategory.vue")
  },
  {
    path: "/FinalInfo",
    name: "FinalInfo",
    meta: { title: "Final Information" },
    component: () => import("../views/FinalInfo.vue")
  },
  {
    path: "/Review",
    name: "Review",
    meta: { title: "Resume" },
    component: () => import("../views/Review.vue")
  },
  {
    path: "/ReviewOld",
    name: "ReviewOld",
    component: () => import("../views/ReviewOld.vue")
  },
  {
    path: "/ReviewGeneralInfo",
    name: "Review General Information",
    meta: { title: "Review General Information" },
    component: () => import("../views/ReviewGeneralInfo.vue")
  },
  {
    path: "/Tester",
    name: TESTER,
    component: () => import("../views/Tester.vue")
  },
  {
    path: "/TermsOfUse",
    name: TERMS,
    component: () => import("../views/TermsAndConditions.vue"),
    meta: { hideNav: true, title: "Terms Of Use" }
  },
  {
    path: "/PrivacyNotice",
    name: PRIVACY,
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: { hideNav: true, title: "Privacy Notice" }
  },
  {
    path: "/CookieNotice",
    name: COOKIE,
    component: () => import("../views/CookieNotice.vue"),
    meta: { hideNav: true, title: "Cookie Notice" }
  },
  {
    path: "/Options",
    name: OPTIONS,
    component: () => import("../views/Options.vue"),
    meta: { title: "User Options" }
  },
  {
    path: "/SubmittedResumes",
    name: "Submitted Resumes",
    component: () => import("../views/CareerCenter/SubmittedResumes.vue"),
    meta: { title: "Submitted Resumes" }
  },
  {
    path: "/SubmitNewResume",
    name: "Submitted New Resume",
    component: () => import("../views/CareerCenter/SubmitNewResume.vue"),
    meta: { title: "Submit New Resume" }
  },
  {
    path: "/ResumeReview/:key",
    name: "Resume Review",
    component: () => import("../views/CareerCenter/ResumeReview.vue"),
    meta: { title: "Resume Review" }
  },
  {
    path: "/ActiveReviewList",
    name: REVIEWLIST,
    component: () => import("../views/Reviewer/ReviewList.vue"),
    meta: { title: "Active Resumes" }
  },
  {
    path: "/ResumeReviewWriter/:user/:id",
    name: REVIEWWRITER,
    component: () => import("../views/Reviewer/ResumeReviewWriter.vue"),
    meta: { title: "Review Resume" }
  },
  {
    path: "/CareerDashboard",
    name: DASHBOARD,
    component: () => import("../views/Reviewer/CareerDashboard.vue"),
    meta: { title: "Career Center Dashboard" }
  },
  {
    path: "/CareerError",
    name: CAREERERROR,
    component: () => import("../views/CareerCenter/CareerError.vue"),
    meta: { title: "Resumation Career Center Error", hideNav: true, hideFooter: true, hideBar: true }
  },
  {
    path: "/AllUsers",
    name: ALLUSERS,
    component: () => import("../views/Reviewer/AllUsers.vue"),
    meta: { title: "All Users" }
  },
  {
    path: "/UserDetails/:key",
    name: USERDETAILS,
    component: () => import("../views/Reviewer/UserDetails.vue"),
    meta: { title: "User Details" }
  },
  {
    path: "/AdminUsers",
    name: ADMINUSERS,
    component: () => import("../views/Reviewer/AdminUsers.vue"),
    meta: { title: "Admin Users" }
  },
  {
    path: "/Loading",
    name: LOADING,
    component: () => import("../views/Loading.vue"),
    meta: { title: "Loading...", hideNav: true, hideFooter: true, hideBar: true }
  },
  {
    path: "/ForgotPassword",
    name: FORGOTPWD,
    component: () => import("../views/Account/ForgotPassword.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Forgot Password" }
  },
  {
    path: "/CreateAccount",
    name: CREATEACCOUNT,
    component: () => import("../views/Account/CreateAccount.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Create Account" }
  },
  {
    path: "/ForgotPasswordAction",
    name: FORGOTPWDACTION,
    component: () => import("../views/Account/ForgotPasswordAction.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Forgot Password" }
  },
  {
    path: "/ConfirmEmailAction",
    name: CONFIRMEMAILACTION,
    component: () => import("../views/Account/ConfirmEmailAction.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Email Confirmed" }
  },
  {
    path: "/EmailConfirmationRequired",
    name: CONFIRMEMAILREQUIRED,
    component: () => import("../views/CareerCenter/ConfirmEmailRequired.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Email Confirmation Required" }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) { //eslint-disable-line 
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  }
});

// login guard
router.beforeResolve((to, from, next) => {  //eslint-disable-line

  let indicatorCookie = document.cookie.split('; ').find(r => r.startsWith("rs_ind")) !== undefined;

  const env = Env();

  const isAdminOnlyPage = ADMINPAGES.some(p => p.toUpperCase() === to?.name?.toUpperCase());
  const isCommonPage = COMMONPAGES.some(p => p.toUpperCase() === to?.name?.toUpperCase());

  if (env === null && to.name !== CAREERERROR) { // if we aren't in a valid env, go to the error page
    next({ name: CAREERERROR });
  } else if (to.name === HOME) {
    next({ name: LOADING }); // removing home page
  } else if (to.name === LOGIN) {
    if (store.state.auth.isAuthed && store.state.auth.isAdmin && !store.state.auth.emailConfirmed) { // Admins have to confirm email before doing anything. Specific case around login...
      next({ name: CONFIRMEMAILREQUIRED });
    }
    next();
  }
  else if (to.name === LOGOUT || to.name === LOADING || to.name === FORGOTPWD || to.name === CREATEACCOUNT || to.name === FORGOTPWDACTION || to.name === CONFIRMEMAILACTION || to.name === CONFIRMEMAILREQUIRED) {
    next();
  } else if (to.name === TERMS || to.name === PRIVACY || to.name === COOKIE) {
    next();
  } else if (to.name === CAREERERROR) {
    next();
  } else if (store.state.auth.isAuthed && indicatorCookie) { // if we're authed, check if this is an admin page or not. // todo: probalby needs a refactor, but should work for now.
    if (store.state.auth.isAdmin && !store.state.auth.emailConfirmed) { // Admins have to confirm email before doing anything
      next({ name: CONFIRMEMAILREQUIRED });
    }
    if (store.state.auth.isAdmin && isAdminOnlyPage) {
      next();
    }
    else if (!store.state.auth.isAdmin && !isAdminOnlyPage) {
      next();
    }
    else if (isCommonPage) {
      next();
    }
    else {
      next({ path: BuildLoginPath(LOGIN, to) });
    }
  } else {
    next({ path: BuildLoginPath(LOGIN, to) });
  }
});

// change page title
// https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
router.afterEach((to, from) => {    //eslint-disable-line 
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${TITLEBASE} - ${to.meta.title}`
    }
    else {
      document.title = TITLEBASE;
    }
  });
});

export default router;
