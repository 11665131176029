import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
    state.reportURL = "";
    state.applicationInsightsKey = "";
    state.applicationName = "";
}

const settingsModule = {
    namespaced: true,
    state: {
        reportURL: "",
        applicationInsightsKey: "",
        applicationName: ""
    },
    getters: {
        getField,
        getSettings(state) {
            return { ...state };
        }
    },
    mutations: {
        updateField,
        loadData(state, payload) {
            log.logTrace(
                "Loading Settings Module",
                "settings_store.loadData"
            );
            state.reportURL = payload.reportServerUrl;
            state.applicationInsightsKey = payload.applicationInsightsKey;
            state.applicationName = payload.applicationName;
        },
        // no removeResume
        logout(state) {
            resetState(state);
        }
    },
    actions: {}
};

export { settingsModule };
