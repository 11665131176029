import * as dutil from "@/utility/dateutil.js";
import * as disp from "@/utility/display.js";

function buildGeneralDisplay(workGeneral) {
  let display =
    workGeneral.companyName.length > 0 ? workGeneral.companyName : "";
  return display.trim();
}

function buildLocationDisplay(workGeneral) {

  return disp.CityStateCountryString(workGeneral.companyCity,
    workGeneral.companyState,
    workGeneral.companyCountry);
}

function buildDateDisplay(workGeneral) {
  let startDate = (Object.prototype.toString.call(workGeneral.startDate) === "[object Date]"
    ? dutil.dateToMonthYear(workGeneral.startDate)
    : "");
  let endDate = (Object.prototype.toString.call(workGeneral.endDate) === "[object Date]"
    ? dutil.dateToMonthYear(workGeneral.endDate)
    : "");

  let sb = "";
  if (startDate.length > 0) {
    sb += startDate.trim();
  }
  if (workGeneral.workingCurrently || endDate.length > 0) {
    if (sb.length > 0) {
      sb += " - ";
    }
    if (workGeneral.workingCurrently) {
      sb += 'Present';
    } else {
      sb += endDate.trim();
    }
  }

  return sb.trim();
}

function buildRolesDisplay(workRole) {
  let display = workRole.positions.map(p => {
    let rstr = p.title;
    if (!disp.IsNullorWhitespace(p.role)) {
      if (!disp.IsNullorWhitespace(rstr)) {
        rstr += " - ";
      }
      rstr += p.role;
    }
    return rstr.trim();
  })

  return display;
}

function buildResponsibilitiesDisplay(workResponsibilities) {
  let display = "";
  if (
    workResponsibilities?.description !== null &&
    workResponsibilities?.description.trim().length > 0
  ) {
    display = workResponsibilities.description;
  }

  return display.trim();
}

function buildDirectReports(workResponsibilities) {
  let display = "";
  if (workResponsibilities?.directReports > 0) {
    display =
      disp.CapitalizeWords(disp.Convert(workResponsibilities?.directReports)) +
      " direct reports";
  }

  return display.trim();
}

function buildAchievements(achievementList) {
  let display = achievementList.map(a =>
    a.useCustom ? a.displayCustom.trim() : a.displayAuto.trim()
  );
  return display;
}

export {
  buildGeneralDisplay,
  buildLocationDisplay,
  buildDateDisplay,
  buildRolesDisplay,
  buildResponsibilitiesDisplay,
  buildDirectReports,
  buildAchievements
};
