import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.targetCompany = "";
  state.companyDoes = "";
  state.targetRole = "";
  state.companyReasons = "";
  state.companyGoals = "";
}

const targetCompanyModule = {
  namespaced: true,
  state: {
    targetCompany: "",
    companyDoes: "",
    targetRole: "",
    companyReasons: "",
    companyGoals: ""
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace(
        "Loading Target Company Module",
        "targetCompany_store.loadData"
      );
      state.targetCompany = payload.targetCompany;
      state.companyDoes = payload.companyDoes;
      state.targetRole = payload.targetRole;
      state.companyReasons = payload.companyReasons;
      state.companyGoals = payload.companyGoals;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveTargetCompany(context) {
      let result = await resApi.PostTargetCompany({
        resumeId: context.rootState.resumeId,
        targetCompany: context.state.targetCompany,
        companyDoes: context.state.companyDoes,
        targetRole: context.state.targetRole,
        companyReasons: context.state.companyReasons,
        companyGoals: context.state.companyGoals
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Target Company data",
          "targetCompany_store.saveTargetCompany"
        );
      }
    }
  }
};

export { targetCompanyModule };
