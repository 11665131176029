import * as envAdminApi from "../../api/CareerApiAdmin";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.reviews = [];
  state.userDisplay = "";
  state.userId = "";
  state.userName = "";
  state.loadUserResumeError = "";
}

const adminUserReviewsModule = {
  namespaced: true,
  state: {
    reviews: [],
    userDisplay: "",
    userName: "",
    userId: "",
    loadUserResumeError: "",
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadReviews(state, payload) {
      log.logTrace("Loading Reviews for User as Admin", "adminUserReviews.loadReviews");
      state.reviews = payload.data;
    },
    setUserInformation(state, payload) {
      log.logTrace("Loading User Information as Admin", "adminUserReviews.setUserInformation");
      state.userDisplay = payload.data.userDisplay;
      state.userName = payload.data.userName;
      state.userId = payload.data.userId;
    },
    setError(state, payload) {
      log.logTrace("Setting error text", "adminUserReviews.setError");
      state.loadUserResumeError = payload;
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async loadResumeReviews(context, userId) {
      let result = await envAdminApi.GetUserReviews(userId);
      if (!result.success) {
        log.logError(
          "Failed to load user's review submissions as admin",
          "adminUserReviews.loadResumeReviews"
        );
        context.commit("setError", "Unable to load user's reviews.");
      } else {
        log.logTrace(`Loaded resume reviews as admin.`, "adminUserReviews.loadResumeReviews");
        context.commit("setError", "");
        context.commit("loadReviews", result);
      }
    },
    async loadUserData(context, userId) {
      let result = await envAdminApi.GetEnvironmentUser(userId);
      if (!result.success) {
        log.logError(
          `Failed to load environment user with ID ${userId} as admin`,
          "adminUserReviews.loadUserData"
        );
        context.commit("setError", "Unable to load user data.");
      } else {
        log.logTrace(`Loaded environment user data as admin.`, "adminUserReviews.loadUserData");
        context.commit("setError", "");
        context.commit("setUserInformation", result);
      }
    },
  }
};

export { adminUserReviewsModule };