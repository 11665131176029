import { Get, Post } from "./ApiBase";

export async function GetEnvironmentUsers() {
  return await Get("/api/GetEnvUsers");
}

export async function GetEnvironmentUser(userId) {
  return await Get(`/api/GetEnvUser?userId=${userId}`);
}

export async function GetEnvironment() {
  return await Get("/api/GetEnvironment");
}

export async function GetActiveReviews() {
  return await Get("/api/GetActiveReviews");
}

export async function GetUserReviews(userId) {
  return await Get(`/api/GetUserReviewsAdmin?userId=${userId}`);
}

export async function AddAdminUser(userName, permission) {
  return await Post('/api/AddAdminUser', { userName, permission });
}

export async function RemoveAdminUser(userName) {
  return await Post('/api/RemoveAdminUser', { userName });
}

export async function GetReview(userId, reviewId) {
  return await Get(`/api/GetReviewAdmin?userId=${userId}&reviewId=${reviewId}`);
}

export async function AddReviewComment(userId, reviewId, commentId, config, text) {
  return await Post('/api/AddReviewComment', { userId, id: reviewId, commentId, config, text });
}

export async function RemoveReviewComment(userId, reviewId, commentId) {
  return await Post('/api/RemoveReviewComment', { userId, id: reviewId, commentId });
}

export async function CompleteReview(userId, reviewId) {
  return await Post('/api/CompleteReview', { userId, id: reviewId });
}