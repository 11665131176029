import Vue from "vue";
import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { StripNull } from "../../utility/general";
import { MaxOrder, CompareOrder, OrderChange } from "../../utility/sorting";
import * as builder from "./work_builder";

function resetState(state) {
  state.workDict = {};
  state.key = "";
}

function isNoIndex(field) {
  if (field === "key" || field === "workDict") {
    return true;
  }
}

function addEmptyEntry(state, key) {
  if (state.workDict[key]) {
    log.logTrace(
      `Entry with key ${key} already exists`,
      "work_store.addEmptyEntry"
    );
    return;
  }
  Vue.set(state.workDict, key, {
    workGeneral: {
      display: "",
      companyName: "",
      companyCity: "",
      companyState: "",
      companyCountry: "",
      industry: "",
      workingCurrently: false,
      startDate: null,
      endDate: null
    },
    workRole: {
      positions: [] //{ title: "", role: "", key: "" }
    },
    workResponsibilities: {
      description: "",
      directReports: 0
    },
    achievementList: [],
    order: MaxOrder(state.workDict) + 1
    // {
    //   background: "",
    //   responsibility: "",
    //   actionResult: "",
    //   measurement: "",
    //   displayAuto: "",
    //   displayCustom: "",
    //   useCustom: "",
    //   id: ""
    // }
  });
}

const workModule = {
  namespaced: true,
  state: {
    workDict: {},
    key: ""
  },
  getters: {
    getField(state) {
      let f = function(path) {
        if (state.key === "" && !isNoIndex(path)) {
          log.logError(`Attempt to get field ${path} with key not set`);
          return null;
        }
        let x = path.split(/[.[\]]+/);
        let startState;
        if (isNoIndex(x[0])) {
          startState = state;
        } else {
          startState = state.workDict[state.key];
        }
        return x.reduce((prev, key) => prev[key], startState);
      };
      return f;
    },
    displayList(state) {
      let output = [];
      for (var key in state.workDict) {
        if (state.workDict[key] != null) {
          output.push({
            key: key,
            display: state.workDict[key].workGeneral.display
          });
        }
      }
      return output;
    },
    fullList(state) {
      let output = [];
      for (var key in state.workDict) {
        if (state.workDict[key] != null) {
          output.push({
            key: key,
            general: builder.buildGeneralDisplay(
              state.workDict[key].workGeneral
            ),
            location: builder.buildLocationDisplay(
              state.workDict[key].workGeneral
            ),
            date: builder.buildDateDisplay(state.workDict[key].workGeneral),
            roles: builder.buildRolesDisplay(state.workDict[key].workRole),
            responsibilities: builder.buildResponsibilitiesDisplay(
              state.workDict[key].workResponsibilities
            ),
            directReports: builder.buildDirectReports(
              state.workDict[key].workResponsibilities
            ),
            achievements: builder.buildAchievements(
              state.workDict[key].achievementList
            ),
            order: state.workDict[key].order
          });
        }
      }
      return output.sort(CompareOrder);
    },
    doesKeyExist(state) {
      let f = function(key) {
        if (state.workDict[key] != null) {
          return true;
        }
        return false;
      };
      return f;
    }
  },
  mutations: {
    updateField(state, { path, value }) {
      if (state.key === "" && !isNoIndex(path)) {
        log.logError("Attempt to get field with key not set");
        return null;
      }
      let x = path.split(/[.[\]]+/);
      let startState;
      if (isNoIndex(x[0])) {
        startState = state;
      } else {
        startState = state.workDict[state.key];
      }

      x.reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          prev[key] = value;
        }
        return prev[key];
      }, startState);
    },
    loadData(state, payload) {
      log.logTrace("Loading Work Module", "work_store.loadData");
      state.workDict = payload.WorkDict;
      // set dates since they'll parse into strings instead of dates... :/
      // eslint-disable-next-line
            for (const [_, workObj] of Object.entries(state.workDict)) {
        if (typeof workObj?.workGeneral !== "undefined") {
          if (workObj.workGeneral.startDate !== null) {
            workObj.workGeneral.startDate = new Date(
              workObj.workGeneral.startDate
            );
          }
          if (workObj.workGeneral.endDate !== null) {
            workObj.workGeneral.endDate = new Date(workObj.workGeneral.endDate);
          }
        }
      }
    },
    createEntry(state, payload) {
      log.logTrace(
        `Adding work entry with key ${payload.key}`,
        "work_store.createEntry"
      );
      addEmptyEntry(state, payload.key);
    },
    removeEntry(state, payload) {
      log.logTrace(
        `Removing work entry with key ${payload.key}`,
        "work_store.removeEntry"
      );
      if (payload.key === state.key) {
        state.key = "";
      }
      Vue.set(state.workDict, payload.key, null);
    },
    setAchievement(state, payload) {
      log.logTrace(
        `Adding achievement for key ${state.key} and id ${payload.id}`,
        "work_store.setAchievement"
      );
      let newAch = {
        background: payload.background,
        responsibility: payload.responsibility,
        actionResult: payload.actionResult,
        measurement: payload.measurement,
        displayAuto: payload.displayAuto,
        displayCustom: payload.displayCustom,
        useCustom: payload.useCustom,
        id: payload.id
      };
      // if it doesn't exist, add it
      if (
        state.workDict[state.key].achievementList.find(
          a => a.id === payload.id
        ) === undefined
      ) {
        state.workDict[state.key].achievementList = [
          ...state.workDict[state.key].achievementList,
          newAch
        ];
      }
      // if it does exist, remove and re-add it
      else {
        state.workDict[state.key].achievementList = [
          ...state.workDict[state.key].achievementList.filter(
            a => a.id != payload.id
          ),
          newAch
        ];
      }
      // Note that the above sets are only reactive for the array.
    },
    removeAchievement(state, payload) {
      log.logTrace(
        `Removing achievement for key ${state.key} and id ${payload}`,
        "work_store.removeAchievement"
      );
      state.workDict[state.key].achievementList = state.workDict[
        state.key
      ].achievementList.filter(a => a.id != payload);
    },
    cleanEntries(state) {
      // Note that this is probaby not a reactive action. If you're bound to fields calling this may break your form
      log.logTrace("cleaning null workDict entries", "work_store.cleanEntries");
      state.workDict = StripNull(state.workDict);
    },
    orderUp(state, payload) {
      OrderChange(state.workDict, payload, "up");
    },
    orderDown(state, payload) {
      OrderChange(state.workDict, payload, "down");
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveWork(context) {
      let cleanDict = StripNull(context.state.workDict);
      let result = await resApi.PostWork({
        resumeId: context.rootState.resumeId,
        workDict: cleanDict
      });
      if (!result.success) {
        log.logWarning("Failed to save work data", "work_store.saveWork");
      }
    }
  }
};

export { workModule };
