import Vue from "vue";
import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { StripNull } from "../../utility/general";
import { MaxOrder, CompareOrder, OrderChange } from "../../utility/sorting";

function resetState(state) {
  state.strengthsDict = [];
  state.key = "";
}

function isNoIndex(field) {
  if (field === "key" || field === "strengthsDict") {
    return true;
  }
}

function addEmptyEntry(state, key) {
  if (state.strengthsDict[key]) {
    log.logTrace(
      `Entry with key ${key} already exists`,
      "strengths_store.addEmptyEntry"
    );
    return;
  }
  Vue.set(state.strengthsDict, key, {
    skills: [], // {id: "", text: ""}
    showCase: "",
    howApply: "",
    display: "",
    order: MaxOrder(state.strengthsDict) + 1
  });
}

const strengthsModule = {
  namespaced: true,
  state: {
    strengthsDict: {},
    key: ""
  },
  getters: {
    getField(state) {
      let f = function(path) {
        if (state.key === "" && !isNoIndex(path)) {
          log.logError(`Attempt to get field ${path} with key not set`);
          return null;
        }
        let x = path.split(/[.[\]]+/);
        let startState;
        if (isNoIndex(x[0])) {
          startState = state;
        } else {
          startState = state.strengthsDict[state.key];
        }
        return x.reduce((prev, key) => prev[key], startState);
      };
      return f;
    },
    displayList(state) {
      let output = [];
      for (var key in state.strengthsDict) {
        if (state.strengthsDict[key] != null) {
          output.push({ key: key, display: state.strengthsDict[key].display });
        }
      }
      return output;
    },
    fullList(state) {
      let output = [];
      for (var key in state.strengthsDict) {
        if (state.strengthsDict[key] != null) {
          output.push({
            key: key,
            display: state.strengthsDict[key].display,
            showCase: state.strengthsDict[key].showCase,
            howApply: state.strengthsDict[key].howApply,
            order: state.strengthsDict[key].order
          });
        }
      }
      return output.sort(CompareOrder);
    },
    doesKeyExist(state) {
      let f = function(key) {
        if (state.strengthsDict[key] != null) {
          return true;
        }
        return false;
      };
      return f;
    }
  },
  mutations: {
    updateField(state, { path, value }) {
      if (state.key === "" && !isNoIndex(path)) {
        log.logError("Attempt to get field with key not set");
        return null;
      }
      let x = path.split(/[.[\]]+/);
      let startState;
      if (isNoIndex(x[0])) {
        startState = state;
      } else {
        startState = state.strengthsDict[state.key];
      }

      x.reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          prev[key] = value;
        }
        return prev[key];
      }, startState);
    },
    loadData(state, payload) {
      log.logTrace("Loading Strengths Module", "strengths_store.loadData");
      state.strengthsDict = payload.StrengthsDict;
    },
    createEntry(state, payload) {
      log.logTrace(
        `Adding strengths entry with key ${payload.key}`,
        "strengths_store.createEntry"
      );
      addEmptyEntry(state, payload.key);
    },
    removeEntry(state, payload) {
      log.logTrace(
        `Removing strengths entry with key ${payload.key}`,
        "strengths_store.removeEntry"
      );
      if (payload.key === state.key) {
        state.key = "";
      }
      Vue.set(state.strengthsDict, payload.key, null);
    },
    cleanEntries(state) {
      // Note that this is probaby not a reactive action. If you're bound to fields calling this may break your form
      log.logTrace(
        "cleaning null strengthsDict entries",
        "strengths_store.cleanEntries"
      );
      state.strengthsDict = StripNull(state.strengthsDict);
    },
    orderUp(state, payload) {
      OrderChange(state.strengthsDict, payload, "up");
    },
    orderDown(state, payload) {
      OrderChange(state.strengthsDict, payload, "down");
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveStrengths(context) {
      let cleanDict = StripNull(context.state.strengthsDict);
      let result = await resApi.PostStrengths({
        resumeId: context.rootState.resumeId,
        strengthsDict: cleanDict
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save strengths data",
          "strengths_store.saveStrengths"
        );
      }
    }
  }
};

export { strengthsModule };
