import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.languages = [];
  state.languagesDisplay = "";
  if (removeBase) {
    state.baseLanguages = [];
  }
}

const languageModule = {
  namespaced: true,
  state: {
    languages: [], // { language: "", languageProficiency: "", id: "" }
    languagesDisplay: "",
    baseLanguages: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Language Module", "language_store.loadData");
      state.languages = payload.Languages;
      state.languagesDisplay = payload.LanguagesDisplay;
    },
    loadBaseLanguages(state, payload) {
      log.logTrace(
        "Loading Base Languages",
        "language_store.loadBaseLanguages"
      );
      state.baseLanguages = payload.baseLanguages;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveLanguages(context) {
      let result = await resApi.PostLanguages({
        resumeId: context.rootState.resumeId,
        languages: context.state.languages,
        languagesDisplay: context.state.languagesDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Language data",
          "language_store.saveLanguages"
        );
      }
    }
  }
};

export { languageModule };
