import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.careerInterests = [];
  state.careerInterestsDisplay = "";
  if (removeBase) {
    state.baseCareerInterests = [];
  }
}

const careerInterestModule = {
  namespaced: true,
  state: {
    careerInterests: [],
    careerInterestsDisplay: "",
    baseCareerInterests: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace(
        "Loading Career Interests Module",
        "careerInterest_store.loadData"
      );
      state.careerInterests = payload.CareerInterests;
      state.careerInterestsDisplay = payload.CareerInterestsDisplay;
    },
    loadBaseCareerInterests(state, payload) {
      log.logTrace(
        "Loading Base Career Interests",
        "careerInterest_store.loadBaseCareerInterests"
      );
      state.baseCareerInterests = payload.baseCareerInterests;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveCareerInterests(context) {
      let result = await resApi.PostCareerInterest({
        resumeId: context.rootState.resumeId,
        careerInterests: context.state.careerInterests,
        careerInterestsDisplay: context.state.careerInterestsDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Career Interest data",
          "careerInterest_store.saveCareerInterests"
        );
      }
    }
  }
};

export { careerInterestModule };
