import * as disp from "@/utility/display.js";

function GetLevelList(hardSkills, level) {
    return hardSkills.filter(h => h.level == level).map(h => h.text);
}

function GetLevelDisplay(hardSkills, level) {
    var skillList = hardSkills.filter(h => h.level == level).map(h => h.text);
    return disp.BuildCommaString("", skillList);
}

function GetLevelLabel(hardSkillLabels, level) {
    let labels = [];
    if (hardSkillLabels === undefined || hardSkillLabels == null || hardSkillLabels.length != 3) {
        labels = GetDefaultLevelLabels();
    }
    let foundLabel = labels.filter(l => l.level == level);
    if (foundLabel === undefined || foundLabel === null)
    {
        return "";
    }
    
    return foundLabel[0].text;
}

function GetDefaultLevelLabels() {
    var retVal = [];
    retVal.push({ text: "Advanced", level: "1" });  // duplicated with Multiselectlevels... consider fix?
    retVal.push({ text: "Familiar", level: "2" });
    retVal.push({ text: "Basic", level: "3" });
    return retVal;
}

export {
    GetLevelList,
    GetLevelDisplay,
    GetLevelLabel,
    GetDefaultLevelLabels,
};
