<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row justify="center">
        <v-col cols="12" md="3">
          <page-left
            :title="title"
            :aside="aside"
            :nextText="nextText"
            :nextLabel="nextLabel"
            :buttonTabIndex="buttonTabIndex"
            :buttonText="buttonText"
            :noBottom="noBottom"
            eventName="nav"
            @nav="nav()"
          >
            <slot name="nav"></slot>
          </page-left>
        </v-col>
        <v-col cols="12" md="9" class="pl-4 pl-md-10">
          <v-row class="pt-4">
            <v-form ref="pageMainForm" class="wide">
              <slot></slot>
            </v-form>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="!noBottom">
          <page-bottom
            class="pt-4"
            v-if="this.$vuetify.breakpoint.smAndDown"
            :nextText="nextText"
            :nextLabel="nextLabel"
            :buttonTabIndex="buttonTabIndex"
            :buttonText="buttonText"
            eventName="nav"
            @nav="nav()"
          ></page-bottom>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageLeft from "./PageLeft.vue";
import PageBottom from "./PageBottom.vue";
import { pageCallback } from "../../utility/unloadHandler";

// @ is an alias to /src
export default {
  name: "PageMain",
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: "",
    },
    aside: {
      type: [String, Array],
      default: "",
    },
    nextText: {
      type: String,
      default: "",
    },
    nextLabel: {
      type: Boolean,
      default: true,
    },
    buttonTabIndex: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "",
    },
    eventName: {
      type: String,
      default: "nav",
    },
    noBottom: {
      type: Boolean,
      deafult: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  components: {
    "page-left": PageLeft,
    "page-bottom": PageBottom,
  },
  methods: {
    nav() {
      if (this.$refs.pageMainForm.validate()) {
        this.$emit(this.eventName);
      } else {
        // work around issue where calling form.validate() doesn't trigger the update:error event
        this.$refs.pageMainForm.$children.forEach((c) =>
          c.checkValidation?.call()
        );
      }
    },
    internalSave() {
      this.$emit("save");
    },
  },
  // Handles route navigation
  beforeDestroy() {
    // Vue 3 updates to beforeUnmount()
    this.internalSave();
  },
  mounted() {
    pageCallback(this.internalSave); // handles leaving the application
  },
  computed: {
    showError() {
      return (
        this.error !== undefined && this.error !== null && this.error.length > 0
      );
    },
  },
};
</script>

<style scoped>
.page-error-bar {
  margin-left: -8px;
  margin-right: -8px;
  background-color: var(--v-error-base);
}
</style>
