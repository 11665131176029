import Vue from "vue";
import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { StripNull } from "../../utility/general";
import { MaxOrder, CompareOrder, OrderChange } from "../../utility/sorting";
import * as builder from "./education_builder";

function resetState(state, removeBase = true) {
  state.educationDict = {};
  state.key = "";
  if (removeBase) {
    state.baseDegrees = [];
  }
}

function isNoIndex(field) {
  if (field === "key" || field === "educationDict") {
    return true;
  }
}

function addEmptyEntry(state, key) {
  if (state.educationDict[key]) {
    log.logTrace(
      `Entry with key ${key} already exists`,
      "education_store.addEmptyEntry"
    );
    return;
  }
  Vue.set(state.educationDict, key, {
    educationGeneral: {
      display: "",
      universityName: "",
      schoolName: "",
      universityCity: "",
      universityState: "",
      country: "",
      graduated: true,
      dateStart: null,
      dateEnd: null,
      hasExpectedGrad: false,
      dateExpected: null,
    },
    educationGPA: {
      display: "",
      gpa: "",
      gpaScale: ""
    },
    educationTests: [
      // { testName: "", testScore: "", testKey: 1 }
    ],
    educationDegree: {
      display: "",
      degreeName: "",
      degreeArea: ""
    },
    educationDegreeMinor: {
      display: "",
      minors: ""
    },
    educationAwards: {
      activities: [], // {id: "" text: ""}
      awards: [],
      honors: [],
      projects: []
    },
    educationAccolades: [],
    order: MaxOrder(state.educationDict) + 1
    // {
    //     achievementLarge: "",
    //     category: "",
    //     how: "",
    //     id: "",
    //     orgName: "",
    //     role: "",
    //     text: "",
    //     displayAuto: "",
    //     displayCustom: "",
    //     useCustom: false,
    //     selected: false,
    // }
  });
}

const educationModule = {
  namespaced: true,
  state: {
    educationDict: {},
    key: "",
    baseDegrees: []
  },
  getters: {
    getField(state) {
      let f = function (path) {
        if (state.key === "" && !isNoIndex(path)) {
          log.logError(`Attempt to get field ${path} with key not set`);
          return null;
        }
        let x = path.split(/[.[\]]+/);
        let startState;
        if (isNoIndex(x[0])) {
          startState = state;
        } else {
          startState = state.educationDict[state.key];
        }
        return x.reduce((prev, key) => prev[key], startState);
      };
      return f;
    },
    displayList(state) {
      let output = [];
      for (var key in state.educationDict) {
        if (state.educationDict[key] != null) {
          output.push({
            key: key,
            display: state.educationDict[key].educationGeneral.display
          });
        }
      }
      return output;
    },
    fullList(state) {
      let output = [];
      for (var key in state.educationDict) {
        if (state.educationDict[key] != null) {
          output.push({
            key: key,
            general: builder.buildGeneralDisplay(
              state.educationDict[key].educationGeneral
            ),
            gpa: builder.buildGPADisplay(state.educationDict[key].educationGPA),
            location: builder.buildLocationDisplay(
              state.educationDict[key].educationGeneral
            ),
            date: builder.buildDateDisplay(
              state.educationDict[key].educationGeneral
            ),
            expectedGrad: builder.buildExpectedGrad(
              state.educationDict[key].educationGeneral
            ),
            degree: builder.buildDegree(
              state.educationDict[key].educationDegree
            ),
            degreeMinor: builder.buildMinorDegree(
              state.educationDict[key].educationDegreeMinor
            ),
            accolades: builder.buildAccolades(
              state.educationDict[key].educationAccolades
            ),
            order: state.educationDict[key].order
          });
        }
      }
      return output.sort(CompareOrder);
    },
    doesKeyExist(state) {
      let f = function (key) {
        if (state.educationDict[key] != null) {
          return true;
        }
        return false;
      };
      return f;
    },
    baseDegrees(state) {
      return state.baseDegrees;
    }
  },
  mutations: {
    updateField(state, { path, value }) {
      if (state.key === "" && !isNoIndex(path)) {
        log.logError("Attempt to get field with key not set");
        return null;
      }
      let x = path.split(/[.[\]]+/);
      let startState;
      if (isNoIndex(x[0])) {
        startState = state;
      } else {
        startState = state.educationDict[state.key];
      }

      x.reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          prev[key] = value;
        }
        return prev[key];
      }, startState);
    },
    loadData(state, payload) {
      log.logTrace("Loading Education Module", "education_store.loadData");
      state.educationDict = payload.EducationDict;

      // eslint-disable-next-line
      for (const [key, eduObj] of Object.entries(state.educationDict)) {

        // upgrade for dateExpected, hasExpectedGrad
        if (eduObj.educationGeneral.hasExpectedGrad === undefined) {
          eduObj.educationGeneral.hasExpectedGrad = false;
        }
        if (eduObj.educationGeneral.dateExpected === undefined) {
          eduObj.educationGeneral.dateExpected = null;
        }

        // set dates since they'll parse into strings instead of dates... :/
        if (eduObj.educationGeneral.dateStart !== null) {
          eduObj.educationGeneral.dateStart = new Date(
            eduObj.educationGeneral.dateStart
          );
        }
        if (eduObj.educationGeneral.dateEnd !== null) {
          eduObj.educationGeneral.dateEnd = new Date(
            eduObj.educationGeneral.dateEnd
          );
        }
        if (eduObj.educationGeneral.dateExpected !== null) {
          eduObj.educationGeneral.dateExpected = new Date(
            eduObj.educationGeneral.dateExpected
          );
        }
      }
    },
    createEntry(state, payload) {
      log.logTrace(
        `Adding education entry with key ${payload.key}`,
        "education_store.createEntry"
      );
      addEmptyEntry(state, payload.key);
    },
    removeEntry(state, payload) {
      log.logTrace(
        `Removing education entry with key ${payload.key}`,
        "education_store.removeEntry"
      );
      if (payload.key === state.key) {
        state.key = "";
      }
      Vue.set(state.educationDict, payload.key, null);
    },
    buildAccolades(state) {
      log.logTrace(
        `Building accolades for key ${state.key}`,
        "education_store.buildAccolades"
      );
      let accs = [
        ...state.educationDict[state.key].educationAwards.activities.map(e => ({
          category: "Activity",
          ...e
        })),
        ...state.educationDict[state.key].educationAwards.awards.map(e => ({
          category: "Award",
          ...e
        })),
        ...state.educationDict[state.key].educationAwards.honors.map(e => ({
          category: "Honor",
          ...e
        })),
        ...state.educationDict[state.key].educationAwards.projects.map(e => ({
          category: "Project",
          ...e
        }))
      ];
      // create accolades from awards if needed, update if needed (not really possible currently)
      accs.forEach(acc => {
        let index = state.educationDict[state.key].educationAccolades.findIndex(
          a => a.id == acc.id
        );
        if (index === -1) {
          state.educationDict[state.key].educationAccolades.push({
            achievementLarge: "",
            category: acc.category,
            how: "",
            id: acc.id,
            orgName: "",
            role: "",
            text: acc.text,
            displayAuto: "", // the actual display value
            displayCustom: "", // the customized display value
            useCustom: false,
            selected: false
          });
        } else {
          Vue.set(
            state.educationDict[state.key].educationAccolades[index],
            "text",
            acc.text
          );
        }
      });
      // remove accolades that don't have a matching award
      Vue.set(
        state.educationDict[state.key],
        "educationAccolades",
        state.educationDict[state.key].educationAccolades.filter(
          acc => accs.find(a => a.id === acc.id) !== undefined
        )
      );
    },
    setAccolade(state, payload) {
      log.logTrace(
        `Adding accolade for key ${state.key} and id ${payload.id}`,
        "education_store.setAccolade"
      );
      let newAcc = {
        achievementLarge: payload.achievementLarge,
        category: payload.category,
        how: payload.how,
        id: payload.id,
        orgName: payload.orgName,
        role: payload.role,
        text: payload.text,
        displayAuto: payload.displayAuto,
        displayCustom: payload.displayCustom,
        useCustom: payload.useCustom,
        selected: payload.selected
      };
      // if it doesn't exist, add it
      if (
        state.educationDict[state.key].educationAccolades.find(
          a => a.id === payload.id
        ) === undefined
      ) {
        state.educationDict[state.key].educationAccolades = [
          ...state.educationDict[state.key].educationAccolades,
          newAcc
        ];
      }
      // if it does exist, remove and re-add it
      else {
        state.educationDict[state.key].educationAccolades = [
          ...state.educationDict[state.key].educationAccolades.filter(
            a => a.id != payload.id
          ),
          newAcc
        ];
      }

      // Note that the above sets are only reactive for the array.
    },
    removeAccolade(state, payload) {
      log.logTrace(
        `Removing accolade for key ${state.key} and id ${payload}`,
        "education_store.removeAccolade"
      );
      state.educationDict[state.key].educationAccolades = state.educationDict[
        state.key
      ].educationAccolades.filter(a => a.id != payload);
    },
    cleanEntries(state) {
      // Note that this is probaby not a reactive action. If you're bound to fields calling this may break your form
      log.logTrace(
        "cleaning null educationDict entries",
        "education_store.cleanEntries"
      );
      state.educationDict = StripNull(state.educationDict);
    },
    orderUp(state, payload) {
      OrderChange(state.educationDict, payload, "up");
    },
    orderDown(state, payload) {
      OrderChange(state.educationDict, payload, "down");
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    },
    loadBaseDegrees(state, payload) {
      log.logTrace(
        "Loading Base Degrees",
        "education_store.loadBaseDegrees"
      );
      state.baseDegrees = payload.baseDegrees;
    },
  },
  actions: {
    async saveEducation(context) {
      let cleanDict = StripNull(context.state.educationDict);
      let result = await resApi.PostEducation({
        resumeId: context.rootState.resumeId,
        educationDict: cleanDict
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save education data",
          "education_store.saveEducation"
        );
      }
    }
  }
};

export { educationModule };
