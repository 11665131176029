import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.professionalOrg = [];
  state.professionalOrgDisplay = "";
  state.volunteerOrg = [];
  state.volunteerOrgDisplay = "";
}

const organizationsModule = {
  namespaced: true,
  state: {
    professionalOrg: [],
    professionalOrgDisplay: "",
    volunteerOrg: [],
    volunteerOrgDisplay: ""
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadProfessionalData(state, payload) {
      log.logTrace(
        "Loading Organizations Module (Professional)",
        "organizations_store.loadProfessionalData"
      );
      state.professionalOrg = payload.ProfessionalOrg;
      state.professionalOrgDisplay = payload.ProfessionalOrgDisplay;
    },
    loadVolunteerData(state, payload) {
      log.logTrace(
        "Loading Organizations Module (Volunteer)",
        "organizations_store.loadVolunteerData"
      );
      state.volunteerOrg = payload.VolunteerOrg;
      state.volunteerOrgDisplay = payload.VolunteerOrgDisplay;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveOrganizations(context) {
      let resultP, resultV;

      resultP = resApi.PostProfessionalOrganization({
        resumeId: context.rootState.resumeId,
        professionalOrg: context.state.professionalOrg,
        professionalOrgDisplay: context.state.professionalOrgDisplay
      });
      resultV = resApi.PostVolunteerOrganization({
        resumeId: context.rootState.resumeId,
        volunteerOrg: context.state.volunteerOrg,
        volunteerOrgDisplay: context.state.volunteerOrgDisplay
      });

      [resultP, resultV] = (await Promise.allSettled([resultP, resultV])).map(
        x => x.value
      );

      if (!resultP.success) {
        log.logWarning(
          "Failed to save Professional Organization data",
          "organizations_store.saveOrganizations"
        );
      }

      if (!resultV.success) {
        log.logWarning(
          "Failed to save Volunteer Organization data",
          "organizations_store.saveOrganizations"
        );
      }
    }
  }
};

export { organizationsModule };
