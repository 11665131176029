<template>
  <status-bar v-model="hasValue" :inError="inError">
    <v-file-input
      ref="fileInput"
      v-model="internalValue"
      :label="label"
      :accept="accept"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :show-size="showSize"
      :rules="rules"
      v-on:change="updateValue()"
      @update:error="handleError($event)"
    ></v-file-input>
  </status-bar>
</template>

<script>
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationFileInput",
  components: {
    "status-bar": StatusBar
  },
  data: () => ({
    inError: false,
    internalValue: new File([], ''),
  }),
  props: {
    value: {
      type: File
    },
    label: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    },
    tabindex: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSize: {
        type: Boolean,
        default: false,
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateValue: function() {
      this.$emit("input", this.internalValue);
    },
    handleError(evt) {
      this.inError = evt;
    },
    checkValidation() {
      // work around issue where calling form.validate() doesn't trigger the update:error event
      this.inError = !this.$refs.fileInput.valid;
    }
  },
  mounted() {},
  computed: {
     hasValue() {
      if (this.internalValue?.name?.length > 0) {
        return "V";
      }
      return "";
    }
  }
};
</script>
