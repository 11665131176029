<template>
  <v-footer app :class="$vuetify.breakpoint.xs ? 'footer-text-xs' : 'footer-text' ">
    <span class="footer-link">&copy; 2022 Resumation LLC</span>
    <v-spacer></v-spacer>
    <a href="/TermsOfUse" target="_blank" class="footer-link">
      Terms Of Use
    </a>
    <a href="/PrivacyNotice" target="_blank" class="footer-link pl-2">
      Privacy Notice
    </a>
    <a href="/CookieNotice" target="_blank" class="footer-link pl-2">
      Cookie Notice
    </a>
  </v-footer>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Footer",
  data: () => ({}),
  props: {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.footer-link {
  text-decoration: none;
  color: var(--v-footerText-base);
}

.footer-text {
  font-size: 1rem;
}

.footer-text-xs {
  font-size: .5rem;
}
</style>