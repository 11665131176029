<template>
  <v-container fluid class="career-login height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-card-title
            class="
              d-flex
              justify-space-between
              wide
              white--text
              elevation-5
              primary-bkg
              py-7
            "
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="8"
                class="d-flex justify-center justify-sm-start"
              >
                <v-img
                  width="300"
                  max-width="300"
                  :src="logoSrc"
                  contain
                  class="ml-3 h-40"
                ></v-img>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center justify-sm-end"
              >
                <div class="login-title pt-4 pt-sm-0 pr-md-4">Resume Tool</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              To get started building your resume, sign in:
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text v-if="managedAllowed">
            <div class="d-flex justify-center">
              <p>Use Email and Password</p>
            </div>
            <div class="px-5">
              <v-form ref="form" v-model="valid" @submit.prevent="logIn()">
                <v-text-field
                  persistent-placeholder
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  type="text"
                  dense
                  class="pb-1"
                ></v-text-field>
                <v-text-field
                  persistent-placeholder
                  v-model="password"
                  label="Password"
                  dense
                  class="pb-1"
                  :rules="pwdRules"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <div class="d-flex justify-center" v-if="loginErrorText !== ''">
                  <p class="error--text text-body-1 font-weight-medium">
                    {{ loginErrorText }}
                  </p>
                </div>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <div class="pb-2">
                      <a
                        @click="navTo('/ForgotPassword')"
                        class="text-body-1 text-decoration-underline"
                        >Forgot your password?</a
                      >
                    </div>
                    <div class="pb-2">
                      <a
                        @click="navTo('/CreateAccount')"
                        class="text-body-1 text-decoration-underline"
                        >Create an Account</a
                      >
                    </div>
                  </div>
                  <div>
                    <v-btn
                      type="submit"
                      :disabled="loading"
                      color="primary"
                      class="px-8"
                      >Log In</v-btn
                    >
                  </div>
                </div>
              </v-form>
            </div>
          </v-card-text>
          <div
            class="d-flex justify-center align-center pt-3"
            v-if="validLogins.length > 0 && managedAllowed"
          >
            <v-divider />
            <div>
              <p class="mb-0 px-5 text--secondary text-body-2">Or</p>
            </div>
            <v-divider />
          </div>
          <v-card-text class="pt-0">
            <v-list>
              <v-list-item-group v-model="selectedItem">
                <v-list-item
                  v-for="(login, i) in validLogins"
                  :key="i"
                  @click="cookieNavTo(login.href, target)"
                  :disabled="login.disabled"
                >
                  <v-list-item-icon>
                    <v-img
                      aspect-ratio="1"
                      width="30"
                      max-width="30"
                      :src="require(`@/assets/${login.filename}`)"
                      contain
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content class="text-body-1">{{
                    login.text
                  }}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { RefreshToken } from "../../api/AuthApi";
import { SetDomainCookie } from "../../cookie/domainCookie";
import {
  SetRedirectCookie,
  ClearRedirectCookie,
  GetRedirectCookie,
} from "../../cookie/redirectCookie";
import { Env } from "../../environment/EnvironmentFactory";
import * as disp from "../../utility/display";
import * as login from "../../api/LoginApi";
import * as log from "../../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "Login",
  data: () => ({
    loading: true,
    selectedItem: -1,
    managedAllowed: false,
    email: "",
    password: "",
    showPwd: false,
    valid: true,
    loginErrorText: "",
    emailRules: [(value) => !!value || "Required."],
    pwdRules: [(v) => !!v || "Required."],
    allLogins: [
      {
        type: "linkedin",
        text: "Continue with LinkedIn",
        filename: "LI-In-Bug.png",
        href: "/api/login/linkedin",
        disabled: false,
      },
      {
        type: "microsoft",
        text: "Continue with a Microsoft Account",
        filename: "ms-symbollockup_mssymbol_19.svg",
        href: "/api/login/microsoft",
        disabled: false,
      },
      {
        type: "google",
        text: "Continue with a Google Account",
        filename: "btn_google_light_normal_ios.svg",
        href: "/api/login/google",
        disabled: false,
      },
    ],
    validLogins: [],
    target: "",
    logoSrc: "",
  }),
  async mounted() {
    Env()
      .GetLogoFilename()
      .then((f) => {
        this.logoSrc = f;
      });

    Env()
      .GetValidLoginProviders()
      .then((f) => {
        this.setLoginProviders(f);
      });

    await this.checkLoggedIn();
  },
  methods: {
    setLoginProviders(validProviders) {
      if (validProviders.length == 0) {
        this.managedAllowed = true;
        this.validLogins = this.allLogins;
      } else {
        if (validProviders.some((i) => i.toLowerCase() == "resumation")) {
          this.managedAllowed = true;
        }
        this.validLogins = this.allLogins.filter((l) =>
          validProviders.some((vp) => vp.toLowerCase() === l.type)
        ); // Ineffecient, but not a problem at the scale of 3
      }
    },
    async checkLoggedIn() {
      try {
        let isLoggedIn = await this.logUserIn();
        this.target = this.$route?.query?.t;
        let redirectCookie = GetRedirectCookie();

        if (isLoggedIn) {
          await RefreshToken();
          this.loading = false;

          if (
            !disp.IsNullorWhitespace(this.target) &&
            this.isValidPath(`/${this.target}`)
          ) {
            this.navTo(`/${this.target}`);
          } else if (!disp.IsNullorWhitespace(redirectCookie)) {
            ClearRedirectCookie();
            this.navTo(`/${redirectCookie}`);
          } else if (this.isAdmin) {
            this.navTo("/CareerDashboard");
          } else {
            this.navTo("/Resume");
          }
        }
      } finally {
        this.loading = false;
      }
    },
    isValidPath(target) {
      return this.$router.getMatchedComponents({ path: target })?.length > 0;
    },
    ...mapActions("auth", ["logUserIn"]),
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    cookieNavTo(href, target) {
      SetDomainCookie(document.location.host);

      if (!disp.IsNullorWhitespace(target)) {
        SetRedirectCookie(target);
      }

      this.hardNavTo(href);
    },
    async logIn() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      if (!this.managedAllowed) {
        log.logError(
          "Managed Login triggered but managed login set to false!",
          "Login.logIn"
        );
        this.loginErrorText = "This login type is not allowed.";
        return;
      }

      try {
        this.loginErrorText = "";
        this.loading = true;

        const loginResponse = await login.LoginManaged(
          this.email,
          this.password
        );
        if (!loginResponse.success) {
          this.loginErrorText = loginResponse.displayText;
        } else {
          this.checkLoggedIn();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
  },
};
</script>