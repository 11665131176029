function BuildCommaString(preceedingString, valueArray) {
  let buildValue = "";
  if (preceedingString.trim().length > 0) {
    buildValue += preceedingString.trim() + " ";
  }

  // valueArray.forEach(element => {
  //   buildValue += " " + element.text + ",";
  // });

  // buildValue = buildValue.slice(0, buildValue.length - 1).trim();

  if (valueArray.length == 1) {
    buildValue += valueArray[0].text;
  } else if (valueArray.length == 2) {
    buildValue += valueArray[0].text + " and " + valueArray[1].text;
  } else {
    let intString = "";
    let i = 0;
    for (i = 0; i < valueArray.length; i++) {
      intString += valueArray[i].text;

      if (i == valueArray.length - 2) {
        intString += ", and ";
      } else if (i != valueArray.length - 1) {
        intString += ", ";
      }
    }

    buildValue += intString;
  }

  return buildValue;
}

function IsNullorWhitespace(string) {
  if (string === undefined) {
    return true;
  }
  if (string === null) {
    return true;
  }
  if (typeof string !== "string") {
    return true;
  }
  if (string.length == 0) {
    return true;
  }
  if (string.trim().length == 0) {
    return true;
  }

  return false;
}

function Unique(a) {
  return Array.from(new Set(a));
}

// Partial Source for conversion code: https://stackoverflow.com/questions/5529934/javascript-numbers-to-words

var ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine"
];
var tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety"
];
var teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen"
];

function convert_millions(num) {
  if (num >= 1000000) {
    return (
      convert_millions(Math.floor(num / 1000000)) +
      " million " +
      convert_thousands(num % 1000000)
    );
  } else {
    return convert_thousands(num);
  }
}

function convert_thousands(num) {
  if (num >= 1000) {
    return (
      convert_hundreds(Math.floor(num / 1000)) +
      " thousand " +
      convert_hundreds(num % 1000)
    );
  } else {
    return convert_hundreds(num);
  }
}

function convert_hundreds(num) {
  if (num > 99) {
    return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
  } else {
    return convert_tens(num);
  }
}

function convert_tens(num) {
  if (num < 10) return ones[num];
  else if (num >= 10 && num < 20) return teens[num - 10];
  else {
    return tens[Math.floor(num / 10)] + " " + ones[num % 10];
  }
}

function Convert(num) {
  if (num === undefined || num === null) {
    return "";
  }
  if (num == 0) return "zero";
  else return convert_millions(num);
}

function CapitalizeWords(text) {
  if (text === undefined || text === null) {
    return text;
  }
  return text
    .split(" ")
    .map(t => {
      if (t.length > 0 && t.toUpperCase() != "AND") {
        t = t[0].toUpperCase() + t.substr(1);
      }
      return t;
    })
    .reduce((a, c) => a + " " + c);
}

function Ellipsis(str, len) {
  if (IsNullorWhitespace(str)) {
    return str;
  }

  if (str?.length > len) {
    return str.substr(0, len - 3) + "...";
  }
  return str;
}

function CityStateCountryString(city, state, country) {
  let cityI = (city?.length > 0 ? city : "").trim();
  let stateI = (state?.length > 0 ? state : "").trim();
  let countryI = (country?.length > 0 ? country : "").trim();

  var sb = "";
  if (cityI.length > 0) {
    sb += cityI;
  }
  if (stateI.length > 0) {
    if (sb.length > 0) {
      sb += ', ';
    }
    sb += stateI;
  }
  if (countryI.length > 0) {
    if (sb.length > 0) {
      sb += ', ';
    }
    sb += countryI;
  }
  return sb.trim();
}

// a or an
function GetLeadingArticle(text) {
  if (text === undefined || text === null || text.length == 0) {
    return "";
  }

  let checkText = text.toUpperCase();
  let retA = true;

  let VowelSet = ['A', 'E', 'I', 'O', 'U']; // AN

  let hSet = ['HONEST', 'HEIR', 'HERB', 'HONOUR', 'HOUR', 'HONOR']; // AN

  let uSet = ['UNION', 'UNITED', 'UNICORN', 'USED', 'ONE', 'UNI']; // A

  VowelSet.forEach(v => {
    if (WordStartsWith(checkText, v)) {
      retA = false;
    }
  })

  hSet.forEach(h => {
    if (WordStartsWith(checkText, h)) {
      retA = false;
    }
  })

  uSet.forEach(u => {
    if (WordStartsWith(checkText, u)) {
      retA = true;
    }
  })

  return retA ? 'a' : 'an';
}

// todo: add unit tests
function WordStartsWith(base, find) {
  if (base === undefined || base === null || base.length == 0) {
    return false;
  }

  if (find === undefined || find === null || find.length == 0) {
    return false;
  }

  if (find.length > base.length) {
    return false;
  }

  for (let i = 0; i < find.length; i++) {
    if (base[i] != find[i]) {
      return false;
    }
  }

  return true;
}

export {
  Unique,
  IsNullorWhitespace,
  BuildCommaString,
  Convert,
  CapitalizeWords,
  Ellipsis,
  CityStateCountryString,
  GetLeadingArticle
};
