import { Post } from "./ApiBase";

export async function CreateNewUser(email, password, name) {
    return await Post("/api/CreateNewUser", { email, password, name });
}

export async function LoginManaged(userName, password) {
    return await Post("/api/LoginManaged", { userName, password });
}

export async function ChangePassword(oldPassword, newPassword) {
    return await Post("/api/ChangePassword", { oldPassword, newPassword });
}

export async function ChangePasswordNonce(nonce, userId, newPassword) {
    return await Post("/api/ChangePasswordNonce", { nonce, userId, newPassword });
}

export async function SendResetEmail(email) {
    return await Post("/api/SendResetEmail", {email});
}

export async function SendConfirmEmail() {
    return await Post("/api/SendConfirmEmail", {});
}

export async function ConfirmEmail(emailNonce, userId) {
    return await Post("/api/ConfirmEmail", {emailNonce, userId});
}