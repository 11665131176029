import * as log from "./logging/logger.js";

var internalCallback = "";

// Set the callback for the page
function pageCallback(callback) {
  internalCallback = callback;
}

function unload() {
  if (internalCallback?.call !== null && internalCallback.call !== undefined) {
    log.logTrace("Calling page callback", "unloadHandler.unload");
    internalCallback.call();
  }
}

export { pageCallback, unload };
