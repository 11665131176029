import axios from "axios";
import { Get, Post } from "./ApiBase";
import * as log from "../utility/logging/logger";

export async function GetUserReviews() {
  return await Get("/api/GetUserReviewsUser");
}

export async function GetReview(reviewId) {
  return await Get(`/api/GetReviewUser?reviewId=${reviewId}`);
}

export async function CreateReview(name, note, fileBase64, contentType, fileName) {
  return await Post('/api/CreateReview', { name, note, fileBase64, contentType, fileName });
}

export async function WithdrawReview(reviewId) {
  return await Post('/api/WithdrawReview', { id: reviewId });
}

export async function ReactivateReview(reviewId) {
  return await Post('/api/ReactivateReview', { id: reviewId });
}

// Special function to handle retrieving a file's contents based on URL.
export async function GetFile(url) {
  await axios
    .get(url)
    .then(result => {
      return { success: true, data: result };
    })
    .catch(error => {
      log.logWarning(`Error: ${error?.toJSON()?.message}`, "CareerApiUser.GetFile");
      return { success: false, error };
    });
}