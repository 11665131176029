<template>
  <v-app id="inspire">
    <div v-if="showNav">
    <nav-drawer-admin v-if="isAdmin" :drawer.sync="drawer"></nav-drawer-admin>
    <nav-drawer-user v-else :drawer.sync="drawer"></nav-drawer-user>
    </div>
    <app-bar
      v-if="!this.$router.currentRoute.meta.hideBar"
      :drawer.sync="drawer"
    ></app-bar>
    <v-main>
      <transition name="page-t">
        <router-view @updateShowNav="updateShowNav"></router-view>
      </transition>
    </v-main>
    <v-footer app v-if="showCookie" color="primary">
      <v-row>
        <v-col cols="12" sm="6">
          <span class="white--text text-body-2">
            Resumation uses cookies to provide our services to you. By using
            this tool, you consent to our use of cookies. For more information,
            read our
            <a
              target="_blank"
              href="/CookieNotice"
              @click.stop
              class="white--text"
            >
              Cookie Notice
            </a>
          </span>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center justify-center height-100 flex-wrap">
            <v-btn outlined @click="acceptAll()" dark class="mr-4 mb-1">
              Accept All
            </v-btn>
            <v-btn outlined @click="cookieManager()" dark class="mb-1">
              Cookie Manager
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-footer>
    <cookie-manager
      v-model="showCookieManager"
      @prompt="updateCookiePrompt()"
    ></cookie-manager>
    <r-footer
      v-if="!this.$router.currentRoute.meta.hideFooter && !showCookie"
    ></r-footer>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import NavDrawerUser from "./components/NavDrawerUser.vue";
import NavDrawerAdmin from "./components/NavDrawerAdmin.vue";
import * as cookie from "./cookie/cookie.js";
import CookieManager from "./components/CookieManager.vue";
import Footer from "./components/Footer.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    "app-bar": AppBar,
    "nav-drawer-user": NavDrawerUser,
    "nav-drawer-admin": NavDrawerAdmin,
    "cookie-manager": CookieManager,
    "r-footer": Footer,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    showNav: false,
    showCookie: false,
    showCookieManager: false,
  }),
  created() {
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    this.showNav = !this.$router.currentRoute.meta.hideNav;
    this.updateCookiePrompt();
  },
  watch: {
    $route: {
      handler(to) {
        this.showNav = !to.meta.hideNav;
      },
      immediate: true,
    },
  },
  methods: {
    updateCookiePrompt() {
      this.showCookie = !cookie.CookiePromptDismissed();
    },
    updateShowNav(payload) {
      this.showNav = payload;
    },
    acceptAll() {
      this.showCookie = false;
      cookie.SetAllowAllCookies();
      cookie.InitGoogleTagManager();
    },
    cookieManager() {
      this.showCookieManager = true;
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
  }
};
</script>