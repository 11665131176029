<template>
  <v-container fluid>
    <v-row class="primary-shade">
      <v-col
        cols="12"
        class="r-col d-flex justify-space-between align-center px-3 py-3"
      >
        <div class="d-flex justify-start align-center">
          <div>
            <v-img
              aspect-ratio="1"
              width="50"
              max-width="50"
              :src="require(`@/assets/logos/r-logo-blue.png`)"
              contain
            >
            </v-img>
          </div>
          <div>
            <v-toolbar-title class="header-title pl-4 white--text">
              Resumation
            </v-toolbar-title>
          </div>
        </div>
        <div v-if="this.$vuetify.breakpoint.smAndUp">
          <v-btn text to="/Resume" dark>Login</v-btn>
          <v-btn
            to="/Resume"
            color="secondary"
            class="grey--text text--darken-4"
            >Get Started</v-btn
          >
        </div>
        <div v-else>
          <v-menu bottom left offset-y tile>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon class="white--text">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="navTo('/Resume')">
                <v-list-item-title class="text-button">Login</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="navTo('/Resume')"
                class="secondary-shade"
              >
                <v-list-item-title class="text-button"
                  >Get Started</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row style="height: 70px"></v-row>
    <v-row class="px-3 px-md-8">
      <v-col cols="12" md="6" style="padding-top: 50px">
        <v-row>
          <v-col cols="12">
            <h1>Better Resumes for Better Results</h1>
            <p class="text-body-1">
              Resumation's advanced process guides you through a series of
              questions designed to help you find and spotlight the best parts
              of your experience. Highlight accomplishments in an engaging way
              and stand out from the crowd.
            </p>
          </v-col>
          <v-col cols="4">
            <v-btn outlined to="/Resume">Get Started</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img contain :src="require(`@/assets/screens/main_3.png`)"> </v-img>
      </v-col>
    </v-row>
    <v-row style="height: 80px"></v-row>
    <v-row
      class="page-callout elevation-5"
      :style="{ 'background-color': secondaryColor }"
    >
      <v-col cols="12" md="8" offset-md="2">
        <h3 class="text-center">
          Learn more about how we help you put your best self forward, or jump
          right in!
        </h3>
      </v-col>
      <v-col cols="6" md="2" offset-md="4">
        <v-btn block text class="mr-4 text-h6 font-weight-bold" href="#how"
          >Learn More</v-btn
        >
      </v-col>
      <v-col cols="6" md="2">
        <v-btn
          block
          text
          class="text-h6 font-weight-bold"
          @click="navTo('/Resume')"
          >Get Started</v-btn
        >
      </v-col>
    </v-row>
    <v-row style="height: 30px"></v-row>
    <v-row class="px-6" id="how">
      <v-col cols="12" sm="10" offset-sm="1" lg="8" offset-lg="2">
        <v-carousel
          interval="9000"
          cycle
          :show-arrows="true"
          hide-delimiters
          v-model="model"
        >
          <v-carousel-item v-for="(item, i) in carousel" :key="i">
            <div class="d-flex justify-center align-center flex-column">
              <div class="pb-3">
                <span class="text-secondary font-italic">{{ item.text }}</span>
              </div>
              <div style="width: 100%; max-width: 500px" class="elevation-4">
                <v-img aspect-ratio="1" :src="item.image" contain> </v-img>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row style="height: 40px"></v-row>
  </v-container>
</template>

<script>
import screen1 from "../assets/screens/screen_1_quick.png";
import screen2 from "../assets/screens/screen_2_questions.png";
import screen3 from "../assets/screens/screen_3_change.png";
import screen4 from "../assets/screens/screen_4_prompt.png";
import screen5 from "../assets/screens/screen_5_example.png";

// @ is an alias to /src
export default {
  name: "Home",
  data: () => ({
    model: 0,
    carousel: [
      {
        image: screen1,
        text: "Resumation helps you get the basic information down quickly, so you can move on to the interesting parts of your resume.",
      },
      {
        image: screen2,
        text: "Questions designed to help you show off your accomplishments in engaging ways.",
      },
      {
        image: screen3,
        text: "Suggested text and paragraphs can be customized to make the resume your own.",
      },
      {
        image: screen4,
        text: "Resumation prompts you just like a consultant would, to build a resume you'll be proud of.",
      },
      {
        image: screen5,
        text: "Explanations and examples help spark ideas and responses when you hit a wall.",
      },
    ],
  }),
  components: {},
  mounted() {},
  methods: {
    updateCarousel(n) {
      this.model = n - 1;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
  },
  computed: {
    carouselCount() {
      return this.colors.length;
    },
    smSize() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        return true;
      }
      return false;
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    secondaryColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    accentColor() {
      return this.$vuetify.theme.themes.light.accent;
    },
  },
};
</script>

<style scoped>
.page-callout {
  padding-top: 20px;
  padding-bottom: 10px;
}

.full-width {
  margin-left: -500px;
  margin-right: -500px;
}

.primary-shade {
  background-color: var(--v-primary-base);
}

.secondary-shade {
  background-color: var(--v-secondary-base);
}
</style>
