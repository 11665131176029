<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="auto "
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card tile>
      <v-system-bar  :height="$vuetify.breakpoint.xs ? 90 : 60" color="primary" class="white--text elevation-5">
        <v-card-title>
          <h4>{{ titleText }}</h4>
        </v-card-title>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <v-card-text class="pb-0">
        <p class="text-body-1 pb-0 pt-3">
          Please read and agree to continue using the application.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-checkbox v-if="termsRequired" v-model="termsAgree">
          <template v-slot:label>
            <div>
              I have read and agree to the
              <a target="_blank" href="/TermsOfUse" @click.stop>
                {{ C_TC }}
              </a>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-if="privacyRequired" v-model="privacyAgree">
          <template v-slot:label>
            <div>
              I have read and agree to the
              <a target="_blank" href="/PrivacyNotice" @click.stop>
                {{ C_PP }}
              </a>
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined color="black" @click="handleDecline()"> Decline </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="black"
          :disabled="!continueEnabled"
          @click="handleAccept()"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as log from "../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "LegalPrompt",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    titleText: "",
    C_Base: "We have updated our ",
    C_TC: "Terms Of Use",
    C_PP: "Privacy Notice",
    termsAgree: false,
    privacyAgree: false
  }),
  methods: {
    ...mapMutations("auth", ["logout"]),
    ...mapActions("legal", ["agreeTerms", "agreePrivacy"]),
    handleAccept() {
      log.logInformation(
        "Terms and/or Privacy Accepted",
        "LegalPrompt.handleAccept"
      );
      this.dialog = false;

      let termsP, privacyP;
      if (this.termsRequired) {
        termsP = this.agreeTerms();
      }
      if (this.privacyRequired) {
        privacyP = this.agreePrivacy();
      }

      Promise.allSettled([termsP, privacyP]);
    },
    handleDecline() {
      this.dialog = false;
      this.navTo("/Logout");
    },
    setup() {
      this.dialog = this.showDialog;
      if (this.termsRequired && this.privacyRequired) {
        this.titleText = this.C_Base + this.C_TC + " and " + this.C_PP;
      } else if (this.termsRequired) {
        this.titleText = this.C_Base + this.C_TC;
      } else if (this.privacyRequired) {
        this.titleText = this.C_Base + this.C_PP;
      } else {
        this.dialog = false;
      }
    },
    navTo(href) {
      this.$router.push({ path: href });
    }
  },
  watch: {
    showDialog: {
      deep: true,
      immediate: true,
      handler() {
        this.setup();
      }
    }
  },
  computed: {
    ...mapGetters("legal", ["privacyRequired", "termsRequired"]),
    continueEnabled() {
      let enabled = true;
      if (
        (this.termsRequired && !this.termsAgree) ||
        (this.privacyRequired && !this.privacyAgree)
      ) {
        enabled = false;
      }
      return enabled;
    }
  }
};
</script>
