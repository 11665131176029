<template>
  <div class="d-flex flex-column align-items-center">
    <div>
      <div>
        <span class="text-body-2 font-italic" v-if="nextLabel">Next:</span>
      </div>
      <div>
        <h3>{{ nextText }}</h3>
      </div>
    </div>
    <div class="pt-3">
      <v-btn block outlined @click="nav()" :tabindex="buttonTabIndex">
        <span v-if="buttonText.length > 0">
          {{ buttonText }}
        </span>
        <span v-else>
          <v-icon class="tight-icon">mdi-chevron-right</v-icon>
          <v-icon class="tight-icon">mdi-chevron-right</v-icon>
          <v-icon class="tight-icon">mdi-chevron-right</v-icon>
          <v-icon class="tight-icon">mdi-chevron-right</v-icon>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "PageLeft",
  data: () => ({}),
  props: {
    nextText: {
      type: String,
      default: "",
    },
    nextLabel: {
      type: Boolean,
      default: true,
    },
    buttonTabIndex: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "",
    },
    eventName: {
      type: String,
      default: "nav",
    },
  },
  components: {},
  methods: {
    nav() {
      this.$emit(this.eventName);
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style>
.tight-icon {
  margin-left: -8px;
  margin-right: -9px;
}
</style>
