function StripNull(o) {
  return Object.fromEntries(Object.entries(o).filter(e => e[1] != null));
}

function HasValue(item, prop) {
  if (item !== undefined && item !== null) {
    if (item[prop] !== undefined) {
      return item[prop].length > 0;
    }
  }
  return false;
}

export { HasValue, StripNull };
