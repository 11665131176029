import { Get, Post } from "./ApiBase";

export async function GetUserPrivacy() {
  return await Get("/api/GetUserPrivacy");
}

export async function PostAgreePrivacy() {
  return await Post("/api/AgreePrivacy", {});
}

export async function PostAgreeTerms() {
  return await Post("/api/AgreeTerms", {});
}
