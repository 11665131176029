// Note that part of this code is duplicated in gtm.js. Unsure how to reconcile the two.

const RSCOOKIENAME = 'rs_cm';

// Cookie schema (string)
//[Notice Dismised][Essential Cookies][Perf Cookies][Analytics Cookies][Advertising Cookies]
//01000

/* -- Internals -- */
function GetCookie() {
    let rs_cookie = document.cookie.split('; ').find(r => r.startsWith(RSCOOKIENAME));
    if (rs_cookie === undefined || rs_cookie === null || rs_cookie.length === 0) {
        return "01000";
    }
    let rs_value = rs_cookie.split("=")[1];
    return rs_value;
}

function SetCookie(value) {
    let cookieString = `${RSCOOKIENAME}=${value}; path=/; max-age=31449600; Secure; SameSite=Strict`;
    document.cookie = cookieString;
}

function SetCookieValue(index, value) {
    let rs_cookie = GetCookie();
    let rs_arr = rs_cookie.split('');
    rs_arr[index] = value;
    rs_cookie = rs_arr.reduce((p, c) => p + c);

    SetCookie(rs_cookie);
}

/* -- Cookie Prompt -- */
function CookiePromptDismissed() {
    try {
        let rs_cookie = GetCookie();
        return rs_cookie[0] === "1";
    }
    catch {
        return true;
    }
}

function SetCookiePrompt(value) {
    if (!(value === "0" || value === "1")) {
        return;  // error - you can't set that value.
    }
    SetCookieValue(0, value);
}

/* -- Essential Cookies -- */
function UseEssential() {
    return true;
    // try {
    //     let rs_cookie = GetCookie();
    //     return rs_cookie[1] === "1";
    // }
    // catch {
    //     return false;
    // }
}

/* -- Performance Cookies -- */
function UsePerf() {
    try {
        let rs_cookie = GetCookie();
        return rs_cookie[2] === "1";
    }
    catch {
        return false;
    }
}

function SetPerfCookie(value) {
    if (!(value === "0" || value === "1")) {
        return;  // error - you can't set that value.
    }
    SetCookieValue(2, value);
}

/* -- Analytics Cookies -- */
function UseAnalytics() {
    try {
        let rs_cookie = GetCookie();
        return rs_cookie[3] === "1";
    }
    catch {
        return false;
    }
}

function SetAnalyticsCookie(value) {
    if (!(value === "0" || value === "1")) {
        return;  // error - you can't set that value.
    }
    SetCookieValue(3, value);
}

function ClearAnalyticsCookies() {
    let cNames = ["_ga", "_ga_GTF72248CB", "1P_JAR"];
    cNames.forEach(name => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    });
}

/* -- Advertising Cookies -- */
function UseAdvertising() {
    try {
        let rs_cookie = GetCookie();
        return rs_cookie[4] === "1";
    }
    catch {
        return false;
    }
}

function SetAdvertisingCookie(value) {
    if (!(value === "0" || value === "1")) {
        return;  // error - you can't set that value.
    }
    SetCookieValue(4, value);
}

/* -- Allow All cookies -- */
function SetAllowAllCookies() {
    SetCookie("11111");
}

/* -- Google Tag Manager setup -- */
function GT(w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
}

function InitGoogleTagManager() {
    GT(window, document, 'script', 'dataLayer', 'GTM-PCK9TDK');
}

export { CookiePromptDismissed, SetCookiePrompt, UseEssential, UsePerf, SetPerfCookie, UseAnalytics, SetAnalyticsCookie, UseAdvertising, SetAdvertisingCookie, SetAllowAllCookies, ClearAnalyticsCookies, InitGoogleTagManager };