<template>
  <nav-drawer-base
    :drawer.sync="innerDrawer"
    :sectionData="sections"
  ></nav-drawer-base>
</template>

<script>
import NavDrawerBase from "./NavDrawerBase.vue";

// @ is an alias to /src
export default {
  name: "NavDrawerAdmin",
  components: {
    "nav-drawer-base": NavDrawerBase,
  },
  data: () => ({
    innerDrawer: false,
    sections: [
      {
        title: "Career Center",
        links: [
          {
            text: "Dashboard",
            path: "/CareerDashboard",
            subLinkSelected: "",
          },
          {
            text: "Submitted Resumes",
            path: "/ActiveReviewList",
            subLinkSelected: "",
          },
          {
            text: "All Users",
            path: "/AllUsers",
            subLinkSelected: "",
          },
          {
            text: "Admin Users",
            path: "/AdminUsers",
            subLinkSelected: "",
          },
        ],
      },
    ],
  }),
  props: {
    drawer: {
      type: Boolean,
      default: () => [],
    },
  },
  mounted() {
    this.innerDrawer = this.drawer;
  },
  methods: {},
  watch: {
    drawer: function () {
      this.innerDrawer = this.drawer;
    },
    innerDrawer: function () {
      this.$emit("update:drawer", this.innerDrawer);
    },
  },
  computed: {},
};
</script>