import * as legalAPI from "../../api/LegalApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.dataLoaded = false;
  state.privacyVersionAccepted = 0;
  state.termsVersionAccepted = 0;
  state.currentPrivacyVersion = 0;
  state.currentTermsVersion = 0;
  state.errorInLoad = false;
}

const legalModule = {
  namespaced: true,
  state: {
    dataLoaded: false,
    privacyVersionAccepted: 0,
    termsVersionAccepted: 0,
    currentPrivacyVersion: 0,
    currentTermsVersion: 0,
    errorInLoad: false
  },
  getters: {
    getField,
    privacyRequired: state => {
      if (!state.dataLoaded) {
        return false;
      }
      return state.privacyVersionAccepted < state.currentPrivacyVersion;
    },
    termsRequired: state => {
      if (!state.dataLoaded) {
        return false;
      }
      return state.termsVersionAccepted < state.currentTermsVersion;
    },
    // no removeResume
    logout(state) {
      resetState(state);
    }
  },
  mutations: {
    updateField,
    setDataLoaded(state) {
      state.dataLoaded = true;
      state.errorInLoad = false;
    },
    setErrorInLoad(state) {
      state.dataLoaded = false;
      state.errorInLoad = true;
    },
    setTermsAccepted(state, payload) {
      state.termsVersionAccepted = payload.termsVersionAccepted;
    },
    setPrivacyAccepted(state, payload) {
      state.privacyVersionAccepted = payload.privacyVersionAccepted;
    },
    setCurrent(state, payload) {
      state.currentPrivacyVersion = payload.currentPrivacyVersion;
      state.currentTermsVersion = payload.currentTermsVersion;
    }
  },
  actions: {
    async fetchTerms(context) {
      let result = await legalAPI.GetUserPrivacy();
      if (result.success) {
        context.commit("setTermsAccepted", {
          termsVersionAccepted: result.termsVersionAccepted
        });
        context.commit("setPrivacyAccepted", {
          privacyVersionAccepted: result.privacyVersionAccepted
        });
        context.commit("setCurrent", {
          currentPrivacyVersion: result.currentPrivacyVersion,
          currentTermsVersion: result.currentTermsVersion
        });
        context.commit("setDataLoaded");
      } else {
        context.commit("setErrorInLoad");
      }
    },
    async agreeTerms(context) {
      let result = await legalAPI.PostAgreeTerms();
      if (!result.success) {
        log.logWarning(
          "Failed to set Terms Agreed To.",
          "legal_store.agreeTerms"
        );
        return;
      }
      context.commit("setTermsAccepted", {
        termsVersionAccepted: context.state.currentTermsVersion
      });
    },
    async agreePrivacy(context) {
      let result = await legalAPI.PostAgreePrivacy();
      if (!result.success) {
        log.logWarning(
          "Failed to set Privacy Agreed To.",
          "legal_store.agreePrivacy"
        );
        return;
      }

      context.commit("setPrivacyAccepted", {
        privacyVersionAccepted: context.state.currentPrivacyVersion
      });
    }
  }
};

export { legalModule };
