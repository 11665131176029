import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state, removeBase = true) {
  state.selectedRoles = [];
  state.roleDisplay = "";
  if (removeBase) {
    state.baseRoles = [];
  }
}

const roleModule = {
  namespaced: true,
  state: {
    selectedRoles: [],
    roleDisplay: "",
    baseRoles: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Roles Module", "role_store.loadData");
      state.selectedRoles = payload.Roles;
      state.roleDisplay = payload.RoleDisplay;
    },
    loadBaseRoles(state, payload) {
      log.logTrace("Loading Base Roles", "role_store.loadBaseRoles");
      state.baseRoles = payload.baseRoles;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveRole(context) {
      let result = await resApi.PostRole({
        resumeId: context.rootState.resumeId,
        roles: context.state.selectedRoles,
        roleDisplay: context.state.roleDisplay
      });
      if (!result.success) {
        log.logWarning("Failed to save role data", "role_store.saveRole");
      }
    }
  }
};

export { roleModule };
